import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { InputProps } from "@material-ui/core/Input";
import { getStorageData } from "../../../../framework/src/Utilities";
import { IMiniCategoriesDataValue } from "../SellerHomeDashboardComponents/Interfaces";
import React, { createRef } from "react";
import { setStorageData } from "framework/src/Utilities";

export interface ICustomField {
  id: string,
  attributes: {
    custom_field_name: string,
    custom_field_detail: {
      data: {
        id: string,
        attributes: {
          id: string,
          field_name: string,
          mandatory: boolean,
          error?: string ,
          custom_fields_options: string[]
        }
      }
    }
  }
}

interface ICustomFieldRes {
  data: ICustomField[]
}

interface ICatalogueIds {
  data?: {
    id: string; 
  }[];
}

interface ISizeCapacity {
  id: string,
  size: string | null,
  size_unit: string,
  capacity: string | null,
  capacity_unit: string,
  hs_code: string,
  prod_model_name: string,
  prod_model_number: string,
  number_of_pieces: string | null
}

interface IFeaturesBullets {
  id: string,
  field_name: string,
  value: string
}

interface IImageUrls {
  id: string,
  url: string
}

interface IImageUrlIds {
  id: string
}

interface IShippingDetails {
  id: string,
  shipping_length: string | null,
  shipping_length_unit: string,
  shipping_height: string | null,
  shipping_height_unit: string,
  shipping_width: string | null,
  shipping_width_unit: string,
  shipping_weight: string | null,
  shipping_weight_unit: string
}

interface ICustomFieldContent {
  id: number,
  custom_field_name: string,
  value: string,
  custom_field_id: number,
  catalogue_id: number
}

interface IContentErrorList {
  mandatory: boolean;
  value: string;
  error?: string;
}

interface IFeatureBulletAttributes { 
  field_name: string; 
  value: string;
  _destroy?: boolean; 
  id?: string 
}

interface IProductContentRes {
  data: {
    attributes: {
        id: string; 
        gtin: string;
        product_color?: string;
        product_title: string;
        unique_psku: string;
        brand_name: string;
        mrp: string | null;
        retail_price: string | null;
        long_description: string;
        whats_in_the_package: string | null;
        country_of_origin: string,
        warranty_days: string,
        warranty_months: string,
        size_and_capacity: ISizeCapacity;
        shipping_detail: IShippingDetails;
        feature_bullets: IFeaturesBullets[];
        image_urls: {
          id: string,
          url: string
        }[];
        custom_fields_contents: ICustomFieldContent[];
  }
  }
}

interface ICreateProdContentRes {
  data: {
    id: string,
    attributes: {
      catalogue_id: string
    }
  },
  errors: string[]
}
interface IImageUrlAttributes { 
  url: string; 
  _destroy?: boolean; 
  id?: string 
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  variant:boolean
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isFetch: boolean;
  catalouge_id: string | number;
  elementsArray: number[];
  elementsArraySpecial: number[];
  imageArray: number[];
  auth_token: string;
  micro_category_data: [];
  mini_category_id: string | number;
  allMiniMicroData: IMiniCategoriesDataValue[];
  category_id: number | string;
  subcategory_id: number | string;
  gtin: number | string;
  unique_psku: string | number;
  unique_sku: string | undefined;
  brand_name: string;
  product_title: string;
  mrp: number | string;
  retail_price: number | string;
  long_description: string;
  whats_in_the_package: string;
  country_of_origin: string;
  dispenser_type: string;
  scent_type: string;
  target_use: string;
  style_name: string;
  feature_bullets_attributes: IFeatureBulletAttributes[];
  catalogue_content_error_list: IContentErrorList[];
  catalogue_content_attributes: {
    id: string | number;
    custom_field_name: string;
    value: string;
  }[];
  shipping_detail_attributes: {
    shipping_length: number | string;
    shipping_length_unit: string;
    shipping_width: number | string;
    shipping_width_unit: string;
    shipping_height: number | string;
    shipping_height_unit: string;
    shipping_weight: number | string;
    shipping_weight_unit: string;
  };
  size_and_capacity_attributes: {
    capacity: string;
    capacity_unit: string;
    hs_code: string;
    number_of_pieces: number | string;
    prod_model_name: string;
    prod_model_number: number | string;
    size: string;
    size_unit: string;
  };
  image_urls_attributes: IImageUrlAttributes[];
  special_features_attributes: { field_name: string; value: string }[];
  error: boolean;
  customFieldList: ICustomField[];
  productContentId: string;
  size_and_capacity_id: string;
  shipping_detail_id: string;
  imageUrlIds: IImageUrlIds[];
  gtinErr: string;
  productTitleErr: string;
  retailPriceErr: string;
  mrpErr: string;
  desErr: string;
  packageErr: string;
  countryErr: string;
  featureErrorObj: string[];
  imageErrorObj: string[];
  sizeErr: string;
  sizeUnitErr: string;
  capacityErr: string;
  capacityUnitErr: string;
  hsCodeErr: string;
  modelNameErr: string;
  modelNumErr: string;
  piecesErr: string;
  weightErr: string;
  lengthErr: string;
  widthErr: string;
  heightErr: string;
  lengthUnitErr: string;
  heightUnitErr: string;
  widthUnitErr: string;
  weightUnitErr: string;
  customFieldErrorObj: string[];
  updatedImageData: { url: string; _destroy?: boolean; id?: string }[];
  updatedFeatureBulletData: { field_name: string; value: string; _destroy?: boolean; id?: string }[];
  customFieldId: string;
  product_color: string;
  productColorErr: string;
  isVariantProduct: boolean
  days: number | string | null ,
  months: number | string | null,
  daysErr: string,
  monthsErr: string
  // Customizable Area End
}
interface SS {}

export default class ProductContentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  inputRef: React.RefObject<InputProps & { click: Function }>;
  apiGetMiniMicroCategoriesCallId: string = "";
  apiPostProductContentCallId: string = "";
  getCustomFiledListId: string = "";
  getProductContentApiCallId: string = "";
  apiUpdateProductContentCallId: string = "";
  apiDelFeatureCallId: string = "";
  apiDelImageCallId: string = "";
  getCatalogueContentAPICallId: string = "";

  productTitleRef:  React.RefObject<HTMLInputElement>;
  colorRef: React.RefObject<HTMLInputElement>;
  retailPriceRef:  React.RefObject<HTMLInputElement>;
  longDescriptionRef:  React.RefObject<HTMLInputElement>;
  whatsInPackageRef:  React.RefObject<HTMLInputElement>;
  countryOriginRef:  React.RefObject<HTMLInputElement>;
  bulletsRef:  React.RefObject<HTMLInputElement>;
  imageUrlRef:  React.RefObject<HTMLInputElement>;
  sizeRef:  React.RefObject<HTMLInputElement>;
  sizeUnitRef:  React.RefObject<HTMLInputElement>;
  capacityRef:  React.RefObject<HTMLInputElement>;
  capacityUnitRef:  React.RefObject<HTMLInputElement>;
  lengthUnitRef:  React.RefObject<HTMLInputElement>;
  lengthRef:  React.RefObject<HTMLInputElement>;
  heightUnitRef:  React.RefObject<HTMLInputElement>;
  heightRef:  React.RefObject<HTMLInputElement>;
  widthUnitRef:  React.RefObject<HTMLInputElement>;
  widthRef:  React.RefObject<HTMLInputElement>;
  weightRef:  React.RefObject<HTMLInputElement>;
  weightUnitRef:  React.RefObject<HTMLInputElement>;
  customRef: React.RefObject<HTMLInputElement>;
  
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      isFetch: false,
      catalouge_id: "",
      elementsArray: [1],
      elementsArraySpecial: [1, 2, 3, 4, 5],
      imageArray: [1],
      auth_token: "",
      allMiniMicroData: [],
      micro_category_data: [],
      mini_category_id: "",
      category_id: "",
      subcategory_id: "",
      gtin: "",
      unique_psku: "",
  unique_sku: '',

      brand_name: "",
      product_title: "",
      mrp: "",
      retail_price: "",
      long_description: "",
      whats_in_the_package: "",
      country_of_origin: "",
      dispenser_type: "",
      scent_type: "",
      target_use: "",
      style_name: "",

      feature_bullets_attributes: [],
      catalogue_content_attributes: [],
      catalogue_content_error_list: [],
      shipping_detail_attributes: {
        shipping_length: "",
        shipping_length_unit: configJSON.selectUnitText,
        shipping_width: "",
        shipping_width_unit: configJSON.selectUnitText,
        shipping_height: "",
        shipping_height_unit: configJSON.selectUnitText,
        shipping_weight: "",
        shipping_weight_unit: configJSON.selectUnitText
      },
      size_and_capacity_attributes: {
        capacity: "",
        capacity_unit: configJSON.selectUnitText,
        hs_code: "",
        number_of_pieces: "",
        prod_model_name: "",
        prod_model_number: "",
        size: "",
        size_unit: configJSON.selectUnitText
      },
      image_urls_attributes: [],
      special_features_attributes: [],
      error: false,
      customFieldList: [],
      productContentId: "",
      size_and_capacity_id: "",
      shipping_detail_id: "",
      imageUrlIds: [],
      updatedImageData: [],
      updatedFeatureBulletData: [],
      gtinErr: "",
      productTitleErr: "",
      retailPriceErr: "",
      mrpErr: "",
      desErr: "",
      packageErr: "",
      countryErr: "",
      featureErrorObj: [],
      imageErrorObj: [],
      sizeErr: "",
      sizeUnitErr: "",
      capacityErr: "",
      capacityUnitErr: "",
      hsCodeErr: "",
      modelNameErr: "",
      modelNumErr: "",
      piecesErr: "",
      weightErr: "",
      lengthErr: "",
      widthErr: "",
      heightErr: "",
      lengthUnitErr: "",
      heightUnitErr: "",
      widthUnitErr: "",
      weightUnitErr: "",
      customFieldErrorObj: [],
      customFieldId: "",
      product_color: "",
      productColorErr: "",
      isVariantProduct: false,
      days: null,
      months: null,
      daysErr: '',
      monthsErr: ''
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.productTitleRef = createRef();
    this.retailPriceRef = createRef();
    this.longDescriptionRef = createRef();
    this.whatsInPackageRef = createRef();
    this.countryOriginRef = createRef();
    this.bulletsRef = createRef();
    this.imageUrlRef = createRef();
    this.sizeRef = createRef();
    this.capacityRef = createRef();
    this.inputRef = createRef();
    this.sizeUnitRef = createRef();
    this.capacityUnitRef = createRef();
    this.lengthUnitRef = createRef();
    this.lengthRef = createRef();
    this.heightUnitRef = createRef();
    this.heightRef = createRef();
    this.widthUnitRef = createRef();
    this.widthRef = createRef();
    this.weightRef = createRef();
    this.weightUnitRef = createRef();
    this.customRef = createRef();
    this.colorRef = createRef();
  }
  // Customizable Area End

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)) || {};

      switch (apiRequestCallId) {
        case this.getCustomFiledListId:
          this.handleCustomFieledlistApiCallResponse(responseJson);
          break;
        case this.apiPostProductContentCallId:
        case this.apiUpdateProductContentCallId:
          this.handleCreateApiResponse(responseJson, errorResponse);
          break;
        case this.getProductContentApiCallId:
        case this.apiDelFeatureCallId:
        case this.apiDelImageCallId:
          this.handleProductContentResponse(responseJson);
          break;
        case this.getCatalogueContentAPICallId:
          this.setCatalogueIds(responseJson);
          break;
      }
    }
  }

  setCatalogueIds = (responseJson: ICatalogueIds) => {
    if (responseJson.data) {
      const { catalogue_content_attributes } = this.state;
      const replacementIds = responseJson.data.map((item: {id: string}) => item.id.toString());
      const modifiedData = catalogue_content_attributes.map((item, index) => ({
        ...item,
        id: replacementIds[index]
      }));

      this.setState({ catalogue_content_attributes: modifiedData });
      this.handleAPICall();
    }
  };

  handleProductContentResponse = async (responseJson: IProductContentRes) => {
    if (responseJson.data) {
      const isExistingProdSel = await getStorageData("sku");
      const isVariant = await getStorageData("isVariantProduct")
      const productAttributes = responseJson.data.attributes;
      await setStorageData("product_content", productAttributes.id);
      await setStorageData("retail_price", productAttributes.retail_price)
      const sizeAndCapacityAttributes = productAttributes?.size_and_capacity || {};
      const shippingDetailAttributes = productAttributes?.shipping_detail || {};
      this.setState({
        productContentId: productAttributes.id,
        gtin: productAttributes.gtin,
        product_color: productAttributes.product_color || "",
        product_title: productAttributes.product_title,
        unique_psku: (isExistingProdSel && !isVariant) ? isExistingProdSel : productAttributes.unique_psku,
        brand_name: productAttributes.brand_name,
        mrp: productAttributes.mrp ? parseFloat(productAttributes.mrp.toString()) : "",
        retail_price: productAttributes.retail_price ? parseFloat(productAttributes.retail_price.toString()) : "",
        long_description: productAttributes.long_description,
        whats_in_the_package: productAttributes.whats_in_the_package || "",
        country_of_origin: productAttributes.country_of_origin,

        size_and_capacity_attributes: this.getSizeAndCapacityAttributes(sizeAndCapacityAttributes),
        elementsArray: this.getElementsArray(productAttributes.feature_bullets),
        feature_bullets_attributes: this.getFeatureBulletsAttributes(productAttributes.feature_bullets),
        updatedFeatureBulletData: this.getFeatureBulletsAttributes(productAttributes.feature_bullets),
        imageArray: this.getImageArray(productAttributes.image_urls),
        image_urls_attributes: this.getImageUrlsAttributes(productAttributes.image_urls),
        shipping_detail_attributes: this.getShippingDetailAttributes(shippingDetailAttributes),

        catalogue_content_attributes: this.getCatalogueContentAttributes(productAttributes.custom_fields_contents),
        size_and_capacity_id: sizeAndCapacityAttributes.id,
        shipping_detail_id: shippingDetailAttributes.id,
        imageUrlIds: this.getImageUrlIds(productAttributes.image_urls),
        days: productAttributes.warranty_days,
        months: productAttributes.warranty_months
      });
    }
  };

  getSizeAndCapacityAttributes = (sizeAndCapacityAttributes: ISizeCapacity) => {
    return {
      capacity: sizeAndCapacityAttributes.capacity || "",
      capacity_unit: sizeAndCapacityAttributes.capacity_unit || configJSON.selectUnitText,
      hs_code: sizeAndCapacityAttributes.hs_code || "",
      number_of_pieces: sizeAndCapacityAttributes.number_of_pieces || "",
      prod_model_name: sizeAndCapacityAttributes.prod_model_name || "",
      prod_model_number: sizeAndCapacityAttributes.prod_model_number || "",
      size: sizeAndCapacityAttributes.size || "",
      size_unit: sizeAndCapacityAttributes.size_unit || configJSON.selectUnitText
    };
  };

  getElementsArray = (featureBullets: IFeaturesBullets[]) => {
    return Array.from({ length: featureBullets.length }, (_, index: number) => featureBullets.length - index);
  };

  getFeatureBulletsAttributes = (featureBullets: IFeaturesBullets[]) => {
    return featureBullets.map((feature: IFeaturesBullets) => ({ field_name: feature.field_name, value: feature.value, id: feature.id }));
  };

  getImageArray = (imageUrls: IImageUrls[]) => {
    return Array.from({ length: imageUrls.length }, (_, index: number) => imageUrls.length - index);
  };

  getImageUrlsAttributes = (imageUrls: IImageUrls[]) => {
    return imageUrls.map((image: IImageUrls) => ({ url: image.url, id: image.id }));
  };

  getShippingDetailAttributes = (shippingDetailAttributes: IShippingDetails) => {
    return {
      shipping_length: shippingDetailAttributes.shipping_length || "",
      shipping_length_unit: shippingDetailAttributes.shipping_length_unit || configJSON.selectUnitText,
      shipping_width: shippingDetailAttributes.shipping_width || "",
      shipping_width_unit: shippingDetailAttributes.shipping_width_unit || configJSON.selectUnitText,
      shipping_height: shippingDetailAttributes.shipping_height || "",
      shipping_height_unit: shippingDetailAttributes.shipping_height_unit || configJSON.selectUnitText,
      shipping_weight: shippingDetailAttributes.shipping_weight || "",
      shipping_weight_unit: shippingDetailAttributes.shipping_weight_unit || configJSON.selectUnitText
    };
  };

  getCatalogueContentAttributes = (customFieldsContents: ICustomFieldContent[]) => {
    this.setState({
      catalogue_content_error_list: this.state.catalogue_content_error_list?.map((item: IContentErrorList, index: number) => {
        return {
          ...item,
          error: "",
          value: customFieldsContents[index]?.value
        };
      })
    });
    return customFieldsContents?.map((item: ICustomFieldContent) => ({ id: item.id, custom_field_name: item.custom_field_name, value: item.value })) || [];
  };

  getImageUrlIds = (imageUrls: IImageUrls[]) => {
    return imageUrls.map((image: IImageUrls) => ({ id: image.id}));
  };

  checkForCondition = (firstCondition: React.RefObject<HTMLInputElement> | null | string | boolean, secondCondition: React.RefObject<HTMLInputElement> | null | boolean) => {
    return firstCondition && secondCondition
  }

  conditionForScroll = (error: string | string[], refValue: React.RefObject<HTMLInputElement> | null = null, firstErrorField: React.RefObject<HTMLInputElement> | null = null) => {
    if(error) {
      return refValue;
    }
    return firstErrorField
  }

  handleFieldValueSet = (formField: string, key: keyof S, value: string) => {
    if (!formField) {
      this.setState({
        [key]: value
      } as unknown as Pick<S, keyof S>);
    }
  }

  handleNext = async () => {
    let firstErrorField : React.RefObject<HTMLInputElement> | null = null;

    const {
      productTitleErr,
      product_title,
      retail_price,
      desErr,
      packageErr,
      productColorErr,
      long_description,
      country_of_origin,
      featureErrorObj,
      whats_in_the_package,
      imageErrorObj,
      image_urls_attributes,
      daysErr,
      monthsErr,
      product_color,
      unique_psku,
      brand_name
    } = this.state;
    
    const validateField = (field: string | number, errorField: keyof S, ref:React.RefObject<HTMLInputElement> | null ) => {
      const currentError = this.state[errorField]; 

      if (!field) {
        this.setState({ [errorField]: configJSON.fieldRequiredText } as unknown as Pick<S, keyof S>);
      }
  
      if (!firstErrorField && (!field || currentError)) {
        firstErrorField = ref;
      }
    };
    
    validateField(product_title, "productTitleErr", this.productTitleRef);
    validateField(product_color, "productColorErr", this.colorRef);
    validateField(retail_price, "retailPriceErr", this.retailPriceRef);
    validateField(long_description, "desErr", this.longDescriptionRef);
    validateField(whats_in_the_package, "packageErr", this.whatsInPackageRef);
    validateField(country_of_origin, "countryErr", this.countryOriginRef);

    if (this.state.feature_bullets_attributes.length === 0) {
      const updatedFeatureErrors = [...this.state.featureErrorObj];
      updatedFeatureErrors[0] = configJSON.fieldRequiredText;
      this.setState({ featureErrorObj: updatedFeatureErrors });
      firstErrorField = firstErrorField || this.bulletsRef;

    }
    if (this.state.image_urls_attributes[0]?.url === "" || this.state.image_urls_attributes.length === 0) {
      const updatedImageErrors = [...this.state.imageErrorObj];
      updatedImageErrors[0] = configJSON.fieldRequiredText;

      this.setState({ imageErrorObj: updatedImageErrors });
      firstErrorField = firstErrorField || this.imageUrlRef;
    }

    this.handleFieldValueSet(this.state.size_and_capacity_attributes.capacity, "sizeErr", ""  )
    this.handleFieldValueSet(this.state.size_and_capacity_attributes.capacity, "capacityErr", ""  )
    
    firstErrorField = this.conditionForScroll(this.state.featureErrorObj[0], this.bulletsRef, firstErrorField)
    firstErrorField = this.conditionForScroll(this.state.sizeErr, this.sizeRef, firstErrorField)
    firstErrorField = this.conditionForScroll(this.state.capacityErr, this.capacityRef, firstErrorField)

    if (firstErrorField && firstErrorField.current ) {
      firstErrorField.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      firstErrorField.current.focus();
    }
    
    const conditionsToCheck = [
      productTitleErr,
      !product_title,
      productColorErr,
      !retail_price,
      desErr,
      packageErr,
      !long_description,
      !country_of_origin,
      featureErrorObj[0],
      !whats_in_the_package,
      imageErrorObj[0],
      !product_color,
      !unique_psku,
      !brand_name,
      daysErr,
      monthsErr,
      !image_urls_attributes[0]?.url, 
      this.handleSizeCheck(),
      this.handleCapacityCheck(),
      this.handleShippingLengthCheck(),
      this.handleShippingHeightCheck(),
      this.handleShippingWidthCheck(),
      this.handleShippingWeightCheck(),
      this.checkMadatoryFieldIsEmpty()
    ];

    if (conditionsToCheck.some(condition => condition)) {
      this.setState({ error: true });
    }
    else {
      this.setState({ error: false });
      this.handleCreateEditProductContent();
    }
    await setStorageData("retail_price", this.state.retail_price)
  };

  handleCreateEditProductContent = async () => {
    const id = await getStorageData("catalog_id");
    const newProductId = await getStorageData("newProductId");
    const contentId = await getStorageData("product_content");
    if (id !== newProductId) {
      this.getCatalogueContentIds(id);
    } else {
      if (!contentId) {
        this.getCatalogueContentIds(id);
      } else this.updateProductContent();
    }
  };

  handleSizeCheck = () => {
    if (this.state.size_and_capacity_attributes.size) {
      if (this.state.size_and_capacity_attributes.size_unit === configJSON.selectUnitText) {
        this.setState({ sizeUnitErr: "Please select size unit" }, () => 
          this.handleErrorScroll(this.state.sizeUnitErr, this.sizeUnitRef)
        );
        return true;
      } else this.setState({ sizeUnitErr: "" });
    }
    if (this.state.size_and_capacity_attributes.size_unit !== configJSON.selectUnitText) {
      if (!this.state.size_and_capacity_attributes.size) {
        this.setState({ sizeErr: configJSON.sizeErrorMsg }, () => 
          this.handleErrorScroll(this.state.sizeErr, this.sizeRef)
        );
        return true;
      } else this.setState({ sizeErr: "" });
    }
    return false;
  };

  handleCapacityCheck = () => {
    if (this.state.size_and_capacity_attributes.capacity) {
      if (this.state.size_and_capacity_attributes.capacity_unit === configJSON.selectUnitText) {
        this.setState({ capacityUnitErr: "Please select capacity unit" }, () =>
          this.handleErrorScroll(this.state.capacityUnitErr, this.capacityUnitRef)
        );
        return true;
      } else this.setState({ capacityUnitErr: "" });
    }
    if (this.state.size_and_capacity_attributes.capacity_unit !== configJSON.selectUnitText) {
      if (!this.state.size_and_capacity_attributes.capacity) {
        this.setState({ capacityErr: configJSON.sizeErrorMsg }, () => 
          this.handleErrorScroll(this.state.capacityErr, this.capacityRef)
        );
        return true;
      } else this.setState({ capacityErr: "" });
    }
    return false;
  };

  handleShippingLengthCheck = () => {
    if (this.state.shipping_detail_attributes.shipping_length) {
      if (this.state.shipping_detail_attributes.shipping_length_unit === configJSON.selectUnitText) {
        this.setState({ lengthUnitErr: "Please select length unit" }, () => {
          this.handleErrorScroll(this.state.lengthUnitErr, this.lengthUnitRef)
        });
        return true;
      } else this.setState({ lengthUnitErr: "" });
    }
    if (this.state.shipping_detail_attributes.shipping_length_unit !== configJSON.selectUnitText) {
      if (!this.state.shipping_detail_attributes.shipping_length) {
        this.setState({ lengthErr: configJSON.sizeErrorMsg }, () => {
          this.handleErrorScroll(this.state.lengthErr, this.lengthRef)
        });
        return true;
      } else this.setState({ lengthErr: "" });
    }
    return false;
  };

  handleShippingHeightCheck = () => {
    if (this.state.shipping_detail_attributes.shipping_height) {
      if (this.state.shipping_detail_attributes.shipping_height_unit === configJSON.selectUnitText) {
        this.setState({ heightUnitErr: "Please select height unit" }, () => 
          this.handleErrorScroll(this.state.heightUnitErr, this.heightUnitRef)
      )
        return true;
      } else this.setState({ heightUnitErr: "" });
    }
    if (this.state.shipping_detail_attributes.shipping_height_unit !== configJSON.selectUnitText) {
      if (!this.state.shipping_detail_attributes.shipping_height) {
        this.setState({ heightErr: configJSON.sizeErrorMsg }, () => 
          this.handleErrorScroll(this.state.heightErr, this.heightRef)
        );
        return true;
      } else this.setState({ heightErr: "" });
    }
    return false;
  };

  handleShippingWidthCheck = () => {
    if (this.state.shipping_detail_attributes.shipping_width) {
      if (this.state.shipping_detail_attributes.shipping_width_unit === configJSON.selectUnitText) {
        this.setState({ widthUnitErr: "Please select width unit" }, () => 
          this.handleErrorScroll(this.state.widthUnitErr, this.widthUnitRef)
      );
        return true;
      } else this.setState({ widthUnitErr: "" });
    }
    if (this.state.shipping_detail_attributes.shipping_width_unit !== configJSON.selectUnitText) {
      if (!this.state.shipping_detail_attributes.shipping_width) {
        this.setState({ widthErr: configJSON.sizeErrorMsg }, () => {
          this.handleErrorScroll(this.state.widthErr, this.widthRef)
        });
        return true;
      } else this.setState({ widthErr: "" });
    }
    return false;
  };

  handleShippingWeightCheck = () => {
    if (this.state.shipping_detail_attributes.shipping_weight) {
      if (this.state.shipping_detail_attributes.shipping_weight_unit === configJSON.selectUnitText) {
        this.setState({ weightUnitErr: "Please select weight unit" }, () =>
          this.handleErrorScroll(this.state.weightUnitErr, this.weightUnitRef)
        );
        return true;
      } else this.setState({ weightUnitErr: "" });
    }
    if (this.state.shipping_detail_attributes.shipping_weight_unit !== configJSON.selectUnitText) {
      if (!this.state.shipping_detail_attributes.shipping_weight) {
        this.setState({ weightErr: configJSON.sizeErrorMsg }, () => 
          this.handleErrorScroll(this.state.weightErr, this.weightRef)
        );
        return true;
      } else this.setState({ weightErr: "" });
    }
    return false;
  };

  handleErrorScroll = (errorName: any, refName:any) => {
    let field: React.RefObject<HTMLInputElement> | null = null;
    field = this.conditionForScroll(errorName, refName, field)
    if (field && field.current ) {
      field.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  handleGtinChangeInput = (value: string) => {
    let gtinRegex = /^(?!\s)\d{8,14}(?<!\s)$/;
    if (value) {
      this.setState({ gtin: value });
      if (gtinRegex.test(value)) {
        this.setState({ gtinErr: "" });
      } else this.setState({ gtinErr: configJSON.gtinErrorMsg });
    } else this.setState({ gtin: "", gtinErr: "" });
  };

  handleProductTitleChangeInput = (value: string) => {
    let titleRegex = configJSON.productTitleRegex;
    this.setState({ product_title: value });
    if (value.length) {
      if (titleRegex.test(value)) {
        this.setState({ productTitleErr: "" });
      } else this.setState({ productTitleErr: configJSON.productTitleErrorMsg });
    } else if(!value) {
      this.setState({ product_title: "", productTitleErr: "" });
    }
  };
  handlemrpChangeInput = (value: string) => {
    const mrp = /^(?!0(\.0{1,2})?$)([1-9]\d{0,6}|0)(\.\d{1,2})?$/;
    this.setState({ mrp: value });
    if (value) {
      if (mrp.test(value)) {
        this.setState({ mrpErr: "" });
      } else this.setState({ mrpErr: configJSON.mrpErrorMsg });
    } else this.setState({ mrp: "", mrpErr: "" });
  };
  handleRetailPriceChangeInput = (value: string) => {
    const retailPrice = /^(?!0(\.0{1,2})?$)([1-9]\d{0,6}|0)(\.\d{1,2})?$/;
    this.setState({ retail_price: value });
    if (value) {
      if (retailPrice.test(value)) {
        this.setState({ retailPriceErr: "" });
      } else this.setState({ retailPriceErr: configJSON.mrpErrorMsg });
    } else this.setState({ retail_price: "", retailPriceErr: configJSON.fieldRequiredText });
  };
  handleLongDescriptionChangeInput = (value: string) => {
    this.setState({ long_description: value });
    if(value) {
      if (!/^[^\s][\s\S]{48,1998}[^\s]$/.test(value)) {
        this.setState({ desErr: configJSON.descErrorMsg});
      }
      else {
        this.setState({ desErr: "" });
      }
    }
    else {
      this.setState({ long_description: "", desErr: configJSON.fieldRequiredText });
    } 
  };
  handleFeatureBulletChange = (index: number, label: string, event: React.ChangeEvent<HTMLInputElement>) => {

    const rawValue = event.target?.value || '';
    const value = rawValue;
    const updatedFeatureBullets = [...this.state.feature_bullets_attributes];
    updatedFeatureBullets[index] = {
      field_name: label,
      value: value
    };
    const updatedFeatureBulletData = [...this.state.updatedFeatureBulletData];
    const existingIndex = updatedFeatureBulletData.findIndex(obj => obj.field_name === label);
    if (existingIndex !== -1) {
      updatedFeatureBulletData[existingIndex].value = value;
    } else {
      updatedFeatureBulletData.push({
        field_name: label,
        value: value
      });
    }
    this.setState({
      feature_bullets_attributes: updatedFeatureBullets,
      updatedFeatureBulletData: updatedFeatureBulletData
    });

    if (/^(?! )[^\s].{3,700}(?<! )$/.test(value)) {
      const updatedFeatureErrors = [...this.state.featureErrorObj];
      updatedFeatureErrors[index] = "";

      this.setState({
        featureErrorObj: updatedFeatureErrors
      });
    } else {
      const updatedFeatureErrors = [...this.state.featureErrorObj];
      updatedFeatureErrors[index] = configJSON.featureErrorMsg;

      this.setState({ featureErrorObj: updatedFeatureErrors });
    }
  };

  handleCustomFieldChange = (index: number, item: ICustomField, value: string ) => {
    // value was getting undefined or null. following check has been added to prevent failure
    if (value && value.length > 30) {
      return;
    }
    const updatedCF = [...this.state.catalogue_content_attributes];
    const errorList = [...this.state.catalogue_content_error_list];

    updatedCF[index] = {
      id: item?.id,
      custom_field_name: item?.attributes?.custom_field_name,
      value: value
    };

    errorList[index] = {
      mandatory: errorList?.[index]?.mandatory,
      value: value,
      error: ""
    };

    this.setState({
      catalogue_content_attributes: updatedCF,
      catalogue_content_error_list: errorList
    });
  };
  handleAddFeature = () => {
    let newElement = this.state.elementsArray.length + 1;
    this.setState(prevState => ({
      elementsArray: [...prevState.elementsArray, newElement]
    }));
  };
  handlewhatsInThePackageChangeInput = (value: string) => {
    let packRegex =configJSON.validation;

    this.setState({ whats_in_the_package: value });
    if (value) {
      if (packRegex.test(value)) {
        this.setState({ packageErr: "" });
      } else this.setState({ packageErr: configJSON.packageErrorMsg });
    } else this.setState({ whats_in_the_package: "", packageErr: "" });
  };
  handleCountryOriginChangeInput = (value: string) => {
    if(value) {
      this.setState({ country_of_origin: value, countryErr: '' });
    }
  };
  handleSizeChangeInput = (value: string) => {
    let sizeRegex = configJSON.commonRegex;
    const updatedSize = { ...this.state.size_and_capacity_attributes, size: value };
    this.setState({
      size_and_capacity_attributes: updatedSize
    });
    if(value) {
      if (sizeRegex.test(value)) {
        this.setState({ sizeErr: "" });
      } else this.setState({ sizeErr: configJSON.sizeErrorMsg });
    }
    else this.setState({ sizeErr: '' });
    
  };
  handleSizeUnitChangeInput = (value: string) => {
    const updatedSizeUnit = { ...this.state.size_and_capacity_attributes, size_unit: value };
    this.setState({
      size_and_capacity_attributes: updatedSizeUnit
    });
    if(value === configJSON.selectUnitText) {
      this.setState({ size_and_capacity_attributes :{ ...this.state.size_and_capacity_attributes, size: '', size_unit: "Select unit"}, sizeErr: ''})
    }
    else if (!this.state.size_and_capacity_attributes.size) {
      this.setState({ sizeErr: configJSON.fieldRequiredText });
    } else this.setState({ sizeUnitErr: "" });
  };
  handleProductColorChangeInput = (value: string) => {
    this.setState({
      product_color: value, productColorErr: ""
    });
  };
  handleCapacityChangeInput = (value: string) => {
    let capacityRegex = configJSON.commonRegex;
    const updatedcapacity = { ...this.state.size_and_capacity_attributes, capacity: value };
    this.setState({
      size_and_capacity_attributes: updatedcapacity
    });
    if(value) {
      if (capacityRegex.test(value)) {
        this.setState({ capacityErr: "" });
      } else this.setState({ capacityErr: configJSON.sizeErrorMsg });
    }
    else this.setState({capacityErr : ''})
  };

  handleCapacityUnitChangeInput = (value: string) => {
    const updatedCapacityUnit = { ...this.state.size_and_capacity_attributes, capacity_unit: value };
    this.setState({
      size_and_capacity_attributes: updatedCapacityUnit
    });
    if(value === configJSON.selectUnitText) {
      this.setState({ size_and_capacity_attributes :{ ...this.state.size_and_capacity_attributes, capacity: '', capacity_unit: configJSON.selectUnitText}, capacityErr: ''})
    }
    else if (!this.state.size_and_capacity_attributes.capacity) {
      this.setState({ capacityErr: configJSON.fieldRequiredText });
    } else this.setState({ capacityErr: "", capacityUnitErr: "" });
  };

  handleHsCodeChangeInput = (value: string) => {
    let hsRegex = /^(?!0+$)\d{6}$/;
    const updatedHscode = { ...this.state.size_and_capacity_attributes, hs_code: value };
    this.setState({
      size_and_capacity_attributes: updatedHscode
    });
    if (hsRegex.test(value)) {
      this.setState({ hsCodeErr: "" });
    } else this.setState({ hsCodeErr: configJSON.hsCodeErrorMsg });
  };
  handleModelNameChangeInput = (value: string) => {
    const updatedModelName = { ...this.state.size_and_capacity_attributes, prod_model_name: value };
    this.setState({
      size_and_capacity_attributes: updatedModelName
    });
    if (value) {
      if (value.length>=1 && value.length<=50) {
        this.setState({ modelNameErr: "" });
      } else this.setState({ modelNameErr: configJSON.modelNameErrorMsg });
    } else this.setState({ size_and_capacity_attributes: { ...this.state.size_and_capacity_attributes, prod_model_name: "" } });
  };
  handleModelNumberChangeInput = (value: string) => {
    let numRegex = /^(?!\s)(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{3,16}(?<!\s)$/;
    const updated = { ...this.state.size_and_capacity_attributes, prod_model_number: value };
    this.setState({
      size_and_capacity_attributes: updated
    });
    if (value) {
      if (numRegex.test(value)) {
        this.setState({ modelNumErr: "" });
      } else this.setState({ modelNumErr: configJSON.modelNumberErrorMsg });
    } else this.setState({ modelNumErr: '',size_and_capacity_attributes: { ...this.state.size_and_capacity_attributes, prod_model_number: "" } });
  };
  handleNumberOfPiecesChangeInput = (value: string) => {
    let piecesRegex = /^(?!0+$)[1-9]\d{0,3}$|10000$/;
    this.setState({
      size_and_capacity_attributes: { ...this.state.size_and_capacity_attributes, number_of_pieces: value }
    });
    if (value) {
      if (piecesRegex.test(value)) {
        this.setState({ piecesErr: "" });
      } else this.setState({ piecesErr: configJSON.piecesErrorMsg });
    } else this.setState({ size_and_capacity_attributes: { ...this.state.size_and_capacity_attributes, number_of_pieces: "" } });
  };
  handleImageUrlChangeInput = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    let imageUrlRegex = configJSON.imageUrlRegex;
    const value = event.target?.value;
    const updatedImageUrls = [...this.state.image_urls_attributes];

    if (updatedImageUrls[index]) {
      updatedImageUrls[index] = { ...updatedImageUrls[index], url: value };
    } else {
      updatedImageUrls[index] = { url: value };
    }

    this.setState({
      image_urls_attributes: updatedImageUrls
    });

    if (imageUrlRegex.test(value)) {
      const updatedImageErrors = [...this.state.imageErrorObj];
      updatedImageErrors[index] = "";

      this.setState({
        imageErrorObj: updatedImageErrors
      });
    } else {
      const updatedImageErrors = [...this.state.imageErrorObj];
      updatedImageErrors[index] = configJSON.imageUrlErrorMsg;

      this.setState({ imageErrorObj: updatedImageErrors });
    }
  };

  handleShippingWeightChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    const updatedShippingWeight = { ...this.state.shipping_detail_attributes, shipping_weight: value };
    this.setState({
      shipping_detail_attributes: updatedShippingWeight
    });
    if (value) {
      if (configJSON.commonRegex.test(value)) {
        this.setState({
          weightErr: ""
        });
      } else this.setState({ weightErr: configJSON.sizeErrorMsg });
    } else this.setState({ shipping_detail_attributes: { ...this.state.shipping_detail_attributes, shipping_weight: "" }, weightErr: "" });
  };
  handleShippingWeightUnitChangeInput = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as string;
    const updatedUnit = { ...this.state.shipping_detail_attributes, shipping_weight_unit: value };
    this.setState({
      shipping_detail_attributes: updatedUnit
    });
    if(value === configJSON.selectUnitText) {
      this.setState({ shipping_detail_attributes :{ ...this.state.shipping_detail_attributes, shipping_weight: '', shipping_weight_unit: configJSON.selectUnitText}, weightErr: ''})
    }
    else if (!this.state.shipping_detail_attributes.shipping_weight) {
      this.setState({ weightErr: configJSON.fieldRequiredText });
    } else this.setState({ weightErr: "", weightUnitErr: "" });
  };

  handleShippingHeightChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    const updatedShippingHeight = { ...this.state.shipping_detail_attributes, shipping_height: value };
    this.setState({
      shipping_detail_attributes: updatedShippingHeight
    });
    if (value) {
      if (configJSON.commonRegex.test(value)) {
        this.setState({
          heightErr: ""
        });
      } else this.setState({ heightErr: configJSON.sizeErrorMsg });
    } else this.setState({ shipping_detail_attributes: { ...this.state.shipping_detail_attributes, shipping_height: "" }, heightErr: "" });
  };
  handleShippingHeightUnitChangeInput = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as string;
    const updatedUnit = { ...this.state.shipping_detail_attributes, shipping_height_unit: value };
    this.setState({
      shipping_detail_attributes: updatedUnit
    });
    if(value === configJSON.selectUnitText) {
      this.setState({ shipping_detail_attributes :{ ...this.state.shipping_detail_attributes, shipping_height: '', shipping_height_unit: configJSON.selectUnitText}, heightErr: ''})
    }
    else if (!this.state.shipping_detail_attributes.shipping_height) {
      this.setState({ heightErr: configJSON.fieldRequiredText });
    } else this.setState({ heightErr: "", heightUnitErr: "" });
  };

  handleShippingLengthChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    const updatedShippinglength = { ...this.state.shipping_detail_attributes, shipping_length: value };
    this.setState({
      shipping_detail_attributes: updatedShippinglength
    });
    if (value) {
      if (configJSON.commonRegex.test(value)) {
        this.setState({
          lengthErr: ""
        });
      } else this.setState({ lengthErr: configJSON.sizeErrorMsg });
    } else this.setState({ shipping_detail_attributes: { ...this.state.shipping_detail_attributes, shipping_length: "" }, lengthErr: "" });
  };

  handleShippingLengthUnitChangeInput = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as string;
    const updatedUnit = { ...this.state.shipping_detail_attributes, shipping_length_unit: value };
    this.setState({
      shipping_detail_attributes: updatedUnit
    });
    if(value === configJSON.selectUnitText) {
      this.setState({ shipping_detail_attributes :{ ...this.state.shipping_detail_attributes, shipping_length: '', shipping_length_unit: configJSON.selectUnitText}, lengthErr: ''})
    }
    else if (!this.state.shipping_detail_attributes.shipping_length) {
      this.setState({ lengthErr: configJSON.fieldRequiredText });
    } else this.setState({ lengthErr: "", lengthUnitErr: "" });
  };

  handleShippingWidthDepthChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    const updatedShippingWidth = { ...this.state.shipping_detail_attributes, shipping_width: value };
    this.setState({
      shipping_detail_attributes: updatedShippingWidth
    });
    if (value) {
      if (configJSON.commonRegex.test(value)) {
        this.setState({
          widthErr: ""
        });
      } else this.setState({ widthErr: configJSON.sizeErrorMsg });
    } else this.setState({ shipping_detail_attributes: { ...this.state.shipping_detail_attributes, shipping_width: "" }, widthErr: "" });
  };

  handleShippingWidthDepthUnitChangeInput = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as string;
    const updatedUnit = { ...this.state.shipping_detail_attributes, shipping_width_unit: value };
    this.setState({
      shipping_detail_attributes: updatedUnit
    });
    if(value === configJSON.selectUnitText) {
      this.setState({ shipping_detail_attributes :{ ...this.state.shipping_detail_attributes, shipping_width: '', shipping_width_unit: configJSON.selectUnitText}, widthErr: ''})
    }
    else if (!this.state.shipping_detail_attributes.shipping_width) {
      this.setState({ widthErr: configJSON.fieldRequiredText });
    } else this.setState({ widthErr: "", widthUnitErr: "" });
  };
  handleAddImage = () => {
    let newElement = this.state.imageArray.length + 1;
    this.setState(prevState => ({
      imageArray: [...prevState.imageArray, newElement]
    }));
  };

  handleAPICall = async () => {
    const catalogId = await getStorageData("catalog_id");
    const id = catalogId;
    const newArray = [this.state];
    const mappedArray = newArray.map(item => {
      const sizeCapacityAttributes = {...item.size_and_capacity_attributes,
        capacity_unit : item.size_and_capacity_attributes.capacity_unit === configJSON.selectUnitText ? null : item.size_and_capacity_attributes.capacity_unit,
        size_unit : item.size_and_capacity_attributes.size_unit === configJSON.selectUnitText ? null : item.size_and_capacity_attributes.size_unit,
      }
      const shippingDetails = {...item.shipping_detail_attributes,
        shipping_length_unit : item.shipping_detail_attributes.shipping_length_unit === configJSON.selectUnitText ? null : item.shipping_detail_attributes.shipping_length_unit,
        shipping_height_unit : item.shipping_detail_attributes.shipping_height_unit === configJSON.selectUnitText ? null : item.shipping_detail_attributes.shipping_height_unit,
        shipping_width_unit : item.shipping_detail_attributes.shipping_width_unit === configJSON.selectUnitText ? null : item.shipping_detail_attributes.shipping_width_unit,
        shipping_weight_unit : item.shipping_detail_attributes.shipping_weight_unit === configJSON.selectUnitText ? null : item.shipping_detail_attributes.shipping_weight_unit
      }
      return {
        attributes: {
          gtin: item.gtin,
          unique_psku: item.unique_psku,
          brand_name: item.brand_name,
          product_title: item.product_title,
          product_color: item.product_color,
          mrp: item.mrp,
          retail_price: item.retail_price,
          long_description: item.long_description,
          whats_in_the_package: item.whats_in_the_package.trim(),
          country_of_origin: item.country_of_origin,
          feature_bullets_attributes: item.feature_bullets_attributes.map((obj: IFeatureBulletAttributes) => {
            const { id, ...rest } = obj;
            return rest;
          }),
          catalogue_content_attributes: item.catalogue_content_attributes,
          size_and_capacity_attributes: sizeCapacityAttributes,
          image_urls_attributes: item.image_urls_attributes.map((obj: IImageUrlAttributes) => {
            const { id, ...rest } = obj;
            return rest;
          }),
          shipping_detail_attributes: shippingDetails,
          warranty_days: item.days,
          warranty_months: item.months,
          dispenser_type: item.dispenser_type,
          scent_type: item.scent_type,
          target_use: item.target_use,
          style_name: item.style_name
        }
      };
    });
    const httpBody = {
      data: mappedArray[0]
    };
    
    const header = { token: this.state.auth_token, "Content-Type": "application/json" };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiPostProductContentCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/${id}/product_contents`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  updateProductContent = () => {
    const newArray = [this.state];

    const mappedArray = newArray.map(item => {
      const updatedShippingDetail = {
        ...item.shipping_detail_attributes,
        shipping_length_unit: item.shipping_detail_attributes.shipping_length_unit === configJSON.selectUnitText ? null : item.shipping_detail_attributes.shipping_length_unit,
        shipping_height_unit: item.shipping_detail_attributes.shipping_height_unit === configJSON.selectUnitText ? null : item.shipping_detail_attributes.shipping_height_unit,
        shipping_weight_unit: item.shipping_detail_attributes.shipping_weight_unit === configJSON.selectUnitText ? null : item.shipping_detail_attributes.shipping_weight_unit,
        shipping_width_unit: item.shipping_detail_attributes.shipping_width_unit === configJSON.selectUnitText ? null : item.shipping_detail_attributes.shipping_width_unit,
        id: this.state.shipping_detail_id
      };
      const updatedSizeAndCapacity = {
        ...item.size_and_capacity_attributes,
        capacity_unit: item.size_and_capacity_attributes.capacity_unit === configJSON.selectUnitText ? null : item.size_and_capacity_attributes.capacity_unit,
        size_unit: item.size_and_capacity_attributes.size_unit === configJSON.selectUnitText ? null : item.size_and_capacity_attributes.size_unit,
        id: this.state.size_and_capacity_id
      };
      return {
        attributes: {
          gtin: item.gtin,
          product_color: this.state.product_color,
          brand_name: item.brand_name,
          product_title: item.product_title,
          mrp: item.mrp,
          retail_price: item.retail_price,
          long_description: item.long_description,
          whats_in_the_package: item.whats_in_the_package.trim(),
          country_of_origin: item.country_of_origin,
          feature_bullets_attributes: item?.updatedFeatureBulletData,
          catalogue_content_attributes: item.catalogue_content_attributes,
          size_and_capacity_attributes: updatedSizeAndCapacity,
          image_urls_attributes: item.image_urls_attributes,
          shipping_detail_attributes: updatedShippingDetail,
          warranty_days: item.days,
          warranty_months: item.months,
          dispenser_type: item.dispenser_type,
          scent_type: item.scent_type,
          target_use: item.target_use,
          style_name: item.style_name
        }
      };
    });
    const httpBody = {
      data: mappedArray[0]
    };
    const header = { token: this.state.auth_token, "Content-Type": "application/json" };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiUpdateProductContentCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/${this.state.catalouge_id}/product_contents/${this.state.productContentId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // Customizable Area End

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const isVariantProduct = await getStorageData("isVariantProduct")
    const variantSku = await getStorageData("variant_sku")
    const catalogId = await getStorageData("catalog_id");
    const newProductId = await getStorageData("newProductId");
    const id = newProductId ? newProductId : catalogId;
    this.setState({ customFieldId: id, isVariantProduct: isVariantProduct ? true : false, unique_sku: variantSku ? variantSku : '' });
    if (id) {
      this.customFiledListFetch(id);
    }
    this.setState({
      auth_token: await getStorageData("authToken"),
      catalouge_id: id,
      brand_name: await getStorageData("brand_name")
    });
    if(!isVariantProduct) {
      this.setState({
        unique_psku: await getStorageData("sku")
      })
    }
  }

  customFiledListFetch = async (id: string) => {
    const headers = {
      // token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomFiledListId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getCustomFiledList(id));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCreateApiResponse = async (responseJson: ICreateProdContentRes, errorResponse: ICreateProdContentRes) => {
    if ("data" in responseJson) {
      await setStorageData("catalog_id", responseJson.data?.attributes?.catalogue_id);
      await setStorageData("product_content", responseJson.data?.id);
      await setStorageData("newProductId", responseJson.data?.attributes?.catalogue_id);
      const msg: Message = new Message(getName(MessageEnum.SendMessage));
      if(!this.props.variant){

        msg.addData(getName(MessageEnum.ChangeActivePanelProductDetails), { activePanel: "variant", activeScreen: "variant",isVariant:false });
      }
      else{
        msg.addData(getName(MessageEnum.ChangeActivePanelProductDetails), { activePanel: "barcode", activeScreen: "barcode" ,isVariant:true});

      }
      this.send(msg);
    }
    if ("errors" in responseJson || errorResponse) {

      if (responseJson.errors && Array.isArray(responseJson.errors)) {
        const errors = responseJson.errors;
    
        if (errors.includes(configJSON.gtinAlreadyTakenErrorMsg)) {
          this.setState({ gtinErr: configJSON.gtinAlreadyTakenErrorMsg });
        }
        if (errors.includes(configJSON.countryNotErrorMsg)) {
          this.setState({ countryErr: configJSON.countryNotErrorMsg });
        }
      } else return;
    }
  };

  handleCustomFieledlistApiCallResponse = async (responseJson: ICustomFieldRes) => {
    if (responseJson && responseJson.data?.length > 0) {
      const mandatoryListArray = responseJson.data?.map((item: ICustomField) => {
        return { mandatory: item?.attributes?.custom_field_detail?.data?.attributes?.mandatory, value: "" };
      });
      this.setState({ customFieldList: responseJson.data, catalogue_content_error_list: mandatoryListArray });
    } else {
      this.setState({ customFieldList: [] });
    }
    await this.getProductContents(this.state.customFieldId);
  };

  checkMadatoryFieldIsEmpty = () => {
    let customErr : React.RefObject<HTMLInputElement> | null = null;
    this.setState(prevState => {
      const updatedErrorList = prevState.catalogue_content_error_list.map(item => {
        if (item.mandatory && (item.value === "" || item.value === null)) {
          customErr = this.conditionForScroll(this.state.catalogue_content_error_list[item as any] as any, this.customRef, customErr)
        if (customErr && customErr.current ) {
          customErr.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        return { ...item, error: configJSON.fieldRequiredText };
        } else {
          return { ...item, error: "" };
        }
      });
      return { catalogue_content_error_list: updatedErrorList };
    });
    const updatedErrorList = this.state.catalogue_content_error_list.map(item => {
      if (item.mandatory && (item.value === "" || item.value === null)) {
        return { ...item, error: configJSON.fieldRequiredText };
      } else {
        return { ...item, error: "" };
      }
    });
    return updatedErrorList.some((item: {error: string}) => item.error !== "");
  };

  getProductContents = async (id: string) => {
    const headers = {
      token: await getStorageData("authToken")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProductContentApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getProductContentEndpoint(id));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDeleteFeatureBullet = (index: number, label: string, id: string | undefined, value: string) => {
    const updatedFeatureBullets = [...this.state.feature_bullets_attributes];
    updatedFeatureBullets[index] = {
      field_name: label,
      value: "",
      _destroy: true,
      id: id || ""
    };
    const updatedData = [...this.state.updatedFeatureBulletData];
    updatedData[index] = {
      field_name: label,
      value: value,
      _destroy: true,
      id: id || ""
    };
    const filteredFeatureBullets = updatedFeatureBullets.filter(bullet => bullet._destroy !== true);
    const updatedElementsArray = [...this.state.elementsArray];
    if (index >= 0 && index < updatedElementsArray.length) {
      updatedElementsArray.splice(index, 1);
      this.setState({
        elementsArray: updatedElementsArray,
        feature_bullets_attributes: filteredFeatureBullets
      });
    }

    this.setState({ updatedFeatureBulletData: updatedData });
    if (id) {
      this.delFeatureAPI(updatedData);
    }
  };

  delFeatureAPI = (data: IFeatureBulletAttributes[]) => {
    const newArray = [this.state];

    const mappedArray = newArray.map(item => {
      return {
        attributes: {
          unique_psku: item.unique_psku,
          feature_bullets_attributes: data
        }
      };
    });
    const httpBody = {
      data: mappedArray[0]
    };
    const header = { token: this.state.auth_token, "Content-Type": "application/json" };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiDelFeatureCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/${this.state.catalouge_id}/product_contents/${this.state.productContentId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleDeleteImage = (index: number, id: string | undefined) => {
    const updatedImageUrls = [...this.state.image_urls_attributes];
    updatedImageUrls[index] = {
      url: "",
      _destroy: true,
      id: id
    };
    const updatedElementsArray = [...this.state.imageArray];
    if (index >= 0 && index < updatedElementsArray.length) {
      updatedElementsArray.splice(index, 1);
      this.setState({
        imageArray: updatedElementsArray
      });
    }
    const updatedImageData = updatedImageUrls.filter(url => url._destroy !== true);
    this.setState({
      image_urls_attributes: updatedImageData,
      updatedImageData: updatedImageUrls
    });
    if (id) {
      this.deleteImageApi(updatedImageUrls);
    }
  };

  deleteImageApi = (data: IImageUrlAttributes[]) => {
    const newArray = [this.state];

    const mappedArray = newArray.map(item => {
      return {
        attributes: {
          unique_psku: item.unique_psku,
          image_urls_attributes: data
        }
      };
    });
    const httpBody = {
      data: mappedArray[0]
    };
    const header = { token: this.state.auth_token, "Content-Type": "application/json" };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiDelImageCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/${this.state.catalouge_id}/product_contents/${this.state.productContentId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleRenderValue = (value: unknown) => {
    return (value as string)?.length === 0 ? configJSON.selectUnitText : value;
  };

  getCatalogueContentIds = (id: string) => {
    const headers = {};
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCatalogueContentAPICallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getCustomFiledList(id));
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(message.id, message);
  };

  handleDaysInput = (value: number | string | any) => {
    const daysRegex = /^(?:[1-9]\d{0,2}|0[1-9]\d?)$/
    this.setState({days: value})
    if(value) {
      if(daysRegex.test(value)){
        this.setState({daysErr: ''})
      }
      else this.setState({daysErr: "Please enter a number between 1 and 999"})
    }
    else this.setState({daysErr: ''})
  }

  handleMonthsInput = (value: number | string | any) => {
    const daysRegex = /^(?:[1-9]\d{0,2}|0[1-9]\d?)$/
    this.setState({months: value})
    if(value) {
      if(daysRegex.test(value)){
        this.setState({monthsErr: ''})
      }
      else this.setState({monthsErr: "Please enter a number between 1 and 999"})
    }
    else this.setState({monthsErr: ''})
  }

  // Customizable Area End
}
