// Customizable Area Start
import {
  Grid,
  Typography,
  withStyles,
  Box,
  InputAdornment,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import React from "react";
import SellerPartnerOrderMgmtController, {
  IOrderData,
  Props,
  configJSON,
} from "./SellerPartnerOrderMgmtController.web";
import {
  CustomizeInputSearchBox,
  OrangeButton,
  SearchIconSVG,
} from "../../dashboard/src/CustomizedElementStyle";
import { edit } from "./assets";
import { Loader2 } from "../../../components/src/Loader.web";
import { Pagination } from "@material-ui/lab";
import ShippingDetails from "./ShoppingCart/ShippingDetails.web";
import TruncatedTextWithTooltip from "../../../components/src/CommonComponents/TruncatedTextWithTooltip.web";

function OrdersPanel(props: any) {
  const { value, children, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a14yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface OrdersTableProps {
  numSelected?: number;

  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderBy?: string;
  rowCount?: any;
  classes: any;
}

function OrdersTableHead(props: OrdersTableProps) {
  const { classes } = props;

  return (
    <TableHead data-test-id="table" className={classes.tableBackgrnd}>
      <TableRow>
        <TableCell className={classes.tableHeaderText}>
          <div className={classes.tableHead}>
            <span>
              {configJSON.orderDateLabel}
            </span>
          </div>
        </TableCell>
        <TableCell className={classes.tableHeaderText}>
          {configJSON.orderDetailsLabel}
        </TableCell>
        <TableCell className={classes.tableHeaderText}>
          {" "}
          {configJSON.imageLabel}
        </TableCell>
        <TableCell className={classes.tableHeaderText}>
          {configJSON.productNameLabel}
        </TableCell>
        <TableCell className={classes.tableHeaderTextOne}>
          {configJSON.customerOptionLabel}
        </TableCell>
        <TableCell className={classes.tableHeaderText}>
          {configJSON.orderStatusLabel}
        </TableCell>
        <TableCell className={classes.tableHeaderText}>
          {configJSON.actionLabel}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
// Customizable Area End

class SellerPartnerOrderManagement extends SellerPartnerOrderMgmtController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getOrderNumber = () => {
    return(
      this.state.ordersInfo.length > 0 ?
        <Box data-test-id="list"  className={this.props.classes.ordersInfo}>{this.state.ordersInfo.length} {this.getOrderCount()}</Box>
      : <Box className={this.props.classes.ordersInfo}>{""}</Box>  
    )
  }

  getActionButtons = (orderStatus:string, item: {id: unknown, accepted: boolean},orderItemId:string) => {
    const rejectCancelStatus = (orderStatus === configJSON.rejectedText) || (orderStatus === configJSON.cancelledText) || (orderStatus === configJSON.refundedText)
    return(
      rejectCancelStatus ? 
        <Button className={this.props.classes.refundOption} disabled={orderStatus === configJSON.refundedText} onClick={this.handleRefund.bind(this,item.id as string)}>
        {configJSON.refundText}
      </Button>
      :
      <>
          <OrangeButton
          data-test-id="acceptBtn"
          onClick={() => this.handleActionBtn(item.id as string,orderItemId)}
            className={this.props.classes.printLabelBtn}
            disabled={item.accepted || (orderStatus === configJSON.rejectedText)}
          >
            {configJSON.acceptBtn}
          </OrangeButton>
          <Button disabled={item.accepted} 
          data-test-id="rejectBtn"
          onClick={() => this.handleRejectBtn(item.id as string)}
          className={this.props.classes.viewOption}>{configJSON.rejectBtn}</Button>
      </>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const menuOptions = this.setOptions()
    const roundedCount = this.getRoundedCount()
    return (
      <>
        <Box className={classes.contentWrapper}>
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography className={classes.tab}>
              {configJSON.fulfilledByPartnerLabel}
            </Typography>
          </Grid>
          {this.state.orderDetails.length === 0 &&
          <>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.orderTextWrapper}>
              <Box className={classes.ordersText}>
                {configJSON.manageOrdersText}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              lg={8}
              md={8}
              sm={12}
              className={classes.searchWrapper}
            >
              <Typography className={classes.searchOrderText}>
                {configJSON.searchOrderIdText}
              </Typography>
              <Grid className={classes.searchBoxWrapper}>
                <CustomizeInputSearchBox
                  id="outlined-start-adornment"
                  className={classes.search}
                  value={this.state.searchOrderValue}
                  placeholder={configJSON.searchOrderPlaceholder}
                  onChange={async(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ searchOrderValue : event.target.value})
                    this.handleSearchPartnerOrders( event)
                  }}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={webStyle.rightMargin}
                      ></InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={webStyle.rightPadding}
                      >
                        <SearchIconSVG />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Box className={classes.ordersWrapper}>
            <Tabs
              data-test-id="tabs"
              variant={"scrollable"}
              scrollButtons={"on"}
              className={classes.tabs}
              value={this.state.tabValue}
              onChange={this.handlePartnerOrdersTabChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: webStyle.tabStyle,
              }}
            >
              <Tab
                label={configJSON.pendingOrdersLabel}
                className={classes.tabsLabel}
                style={{backgroundColor: this.getTabColor(0, this.state.tabValue)}}
                {...a14yProps(0)}
              />
              <Tab
                label={configJSON.allOrdersLabel}
                className={classes.tabsLabel}
                style={{
                  backgroundColor:
                  this.getTabColor(1, this.state.tabValue),
                }}
                {...a14yProps(1)}
              />
            </Tabs>
            {this.state.loading ?
            <Loader2 loading={this.state.loading}/>
            :
            <>
            {[...Array(2)].map((tab: any, index: number) => {
              return (
                <div className={classes.layout}>
                  <OrdersPanel value={this.state.tabValue} index={index}>
                    <Box className={classes.pendingOrdersWrapper}>
                      {this.getOrderNumber()}
                      <Box className={classes.optionsWrapper}>
                        {this.state.tabValue !== 2 &&
                        <>
                        <Button
                        id="filter-menu"
                        data-test-id="filterBtn"
                          className={classes.options}
                          style={webStyle.rightMargin}
                          onClick={this.setFilter.bind(this)}
                        >
                          {configJSON.filtersLabel}
                        </Button>
                        <Menu
                        id="filter-menu"
                        anchorEl={this.state.openFilterAnchor}
                        open={Boolean(this.state.openFilterAnchor)}
                        onClose={this.handleCloseFilters}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        getContentAnchorEl={null}
                        PaperProps={{
                          style: webStyle.menuPropStyle
                        }}
                      >
                      <Typography className={classes.statusText}>{configJSON.orderStatusLabel}</Typography>
                        {menuOptions.map((option:string, index: number) => {
                          return(
                            <MenuItem value={option} className={classes.checkBoxLabel} style={{margin:'-8px -14px -16px',color: this.getCheckBoxColor(this.state.isDelivered)}}>
                        <Checkbox data-test-id="filterOption" className={classes.filterCheckbox} checked={this.state.filterByValue.includes(option)} onChange={() => this.handleFilters(option)}/>
                          {option}
                        </MenuItem>
                          )
                        })}
                       </Menu>
                       </>
                        }
                        <Button
                        id="menu"
                        data-test-id="sortBtn"
                          className={classes.options}
                          onClick={this.setSortValue.bind(this)}
                        >{configJSON.sortingLabel}
                        </Button>
                        <Menu
              data-test-id="sortMenu"
              id="menu"
              anchorEl={this.state.openSortAnchorEl}
              keepMounted
              PaperProps={{
                style: webStyle.sortMenuStyle
              }}
              open={Boolean(this.state.openSortAnchorEl)}
              onClose={this.handleCloseSortMenu}
              
            >
              {configJSON.sortOrderOptions.map((item: { label: string; value: string }) => {
                return (
                  <MenuItem data-test-id="sortOption" className={this.getSortStyle(item.value)} onClick={this.handleSorting.bind(this, item.value)}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Menu>
                      </Box>
                    </Box>
                    {Boolean(this.state.openShipAnchorEl) && <ShippingDetails 
                      id="listingShipping" 
                      order_id={this.state.orderId} 
                      navigation={this.props.navigation} 
                      open={Boolean(this.state.openShipAnchorEl)} 
                      onClose={this.closeShipModal}
                      onSubmit={()=>this.updateOrderStatus(this.state.orderId, 'shipped')}
                    />}
                    <Box style={{ paddingTop: "20px" }}>
                      <TableContainer className={classes.tableContainer}>
                        <Table aria-label="simple table">
                          <OrdersTableHead
                            data-test-id="tableHeader"
                            classes={classes}
                          />
                          
                          <TableBody className={classes.layout}>
                            {this.state.ordersInfo.length > 0 ?
                              this.state.ordersInfo.map((item: IOrderData) => {
                                const isEditStatus = configJSON.editStatusOptions
                                const orderStatus = item.order_status && item.order_status.data.attributes.name
                                const prodImage = item?.order_items?.data[0]?.attributes?.item?.catalogue?.data?.attributes?.product_image
                                const productTitle = item?.order_items?.data[0]?.attributes?.item?.catalogue?.data?.attributes?.product_title
                                const uniquePesku = item?.order_items?.data[0]?.attributes?.item?.catalogue?.data?.attributes?.product_content?.product_attributes?.unique_psku;
                                return (
                                  <TableRow 
                                    key={item.id as string}
                                    style={webStyle.tableHeader}
                                  >
                                    <TableCell
                                      component="td"
                                      className={classes.tableCellContent}
                                    >
                                      <div className={classes.dateWrapper}>
                                        <Box className={classes.day}>
                                          {item.time_passed_since_order_placed}
                                        </Box>
                                        <Box className={classes.buyer}>
                                          {this.getDate(item.ordered_at)}
                                        </Box>
                                        <Box className={classes.gst}>
                                          {`${this.getTime(item.ordered_at)} GST`}
                                        </Box>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      className={classes.tableRowHeaderText}
                                    >
                                      <Box className={classes.buyer}>
                                        {item.order_number}
                                      </Box>
                                      <Box className={classes.detailsWrapper}>
                                        <Typography className={classes.buyer}>
                                          {configJSON.buyerLabel}:
                                        </Typography>
                                        <Typography
                                          className={classes.buyerName}
                                        >
                                          {item.customer?.data?.attributes?.full_name}
                                        </Typography>
                                      </Box>
                                      <Box className={classes.detailsWrapper}>
                                        <Typography className={classes.buyer}>
                                          {configJSON.salesLabel}:
                                        </Typography>
                                        <Typography
                                          className={classes.buyerName}
                                        >
                                          {item.seller?.data?.attributes?.full_name}
                                        </Typography>
                                      </Box>
                                      <Box className={classes.detailsWrapper}>
                                        <Typography className={classes.buyer}>
                                          {configJSON.fullFillmentLabel}
                                        </Typography>
                                        <Typography
                                          className={classes.buyerName}
                                        >
                                          {configJSON.partnerText}
                                        </Typography>
                                      </Box>
                                    </TableCell>

                                    <TableCell 
                                        data-test-id="getOrderDetail"
                                        style={webStyle.title}
                                        onClick={this.handleOrderDetails.bind(this,item.id as string)}>
                                      <Box className={classes.imageBox}>
                                      <img
                                        src={prodImage}
                                        className={classes.prodImage}
                                        alt="img"
                                      />
                                      </Box>
                                    </TableCell>

                                    <TableCell 
                                        className={classes.prodTitle} 
                                        data-test-id="getOrderDetail"
                                        onClick={this.handleOrderDetails.bind(this,item.id as string)}>
                                      <Box
                                        style={{wordBreak: 'break-all'}}
                                        className={classes.buyer}
                                      >
                                        <TruncatedTextWithTooltip text={productTitle} maxChar={50}/>
                                      </Box>
                                      <Box className={classes.detailsWrapper}>
                                        <Typography className={classes.buyer}>
                                          {configJSON.pskuLabel}
                                        </Typography>
                                        <Typography
                                          className={classes.buyerName}
                                        >
                                          {uniquePesku}
                                        </Typography>
                                      </Box>
                                      <Box className={classes.detailsWrapper}>
                                        <Typography className={classes.buyer}>
                                          {configJSON.unitPriceInAED}
                                        </Typography>
                                        <Typography
                                          className={classes.buyerName}
                                        >
                                          {item.final_price}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      <Box
                                        className={` ${classes.customerOption}`}
                                      >
                                        {configJSON.standardText}
                                      </Box>
                                    </TableCell>
                                    <TableCell style={webStyle.statusWrap}>
                                      <Grid container style={webStyle.statusGrid}>
                                      <Box
                                        className={this.getOrderStatusClass(
                                          item?.order_status?.data?.attributes?.name
                                        )}
                                      >
                                        {this.getOrdersStatus(item?.order_status?.data?.attributes?.name)}
                                      </Box>
                                      
                                      <IconButton
                                      style={webStyle.editIcon}
                                aria-label="more"
                                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.handleEdit(event,item?.order_status?.data?.attributes?.name , item.id as string, item?.shipped_order_details[0])}
                                disabled={isEditStatus.includes(orderStatus)}
                                data-test-id="editIcon"
                              >
                                <img src={edit} alt="edit_icon" style={{height:'16px'}} />
                              </IconButton>
                              </Grid>
                              <Menu
                                id="long-menu"
                                anchorEl={this.state.anchorEl}
                                open={Boolean(this.state.anchorEl)}
                                onClose={this.handleClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                                getContentAnchorEl={null}
                                PaperProps={{
                                  style: webStyle.editOptions,
                                }}
                              >
                                
                              <Typography className={classes.statusText}>{configJSON.orderStatusLabel}</Typography>
                                <MenuItem className={classes.checkBoxLabel} style={{margin:'-8px -14px -16px',color: this.getCheckBoxColor(this.state.isDelivered)}}>
                                <Checkbox data-test-id="delivered" className={classes.delivered} defaultChecked={orderStatus === configJSON.deliveredText} checked={this.state.isDelivered} onChange={this.handleDeliveredCheckbox} disabled={this.state.isProcessing}/>
                                  {configJSON.deliveredText}
                                </MenuItem>
                                <MenuItem  className={classes.checkBoxLabel} style={{margin:'0 -14px -16px',color: this.getCheckBoxColor(this.state.isShipped)}}>
                                <Checkbox data-test-id="shipped" className={classes.delivered} defaultChecked={orderStatus === configJSON.shippedText} checked={this.state.isShipped} onChange={this.handleShippedCheckbox}/>
                                  {configJSON.shippedText}
                                </MenuItem>
                                <MenuItem className={classes.checkBoxLabel} style={{margin:'0 -14px -10px',color: this.getCheckBoxColor(this.state.isProcessing)}}>
                                <Checkbox data-test-id="processing" className={classes.delivered} defaultChecked={orderStatus === configJSON.processingText} checked={this.state.isProcessing} onChange={this.handleProcessingCheckbox} disabled={this.state.isShipped}/>
                                  {configJSON.processingText}
                                </MenuItem> 
                               </Menu>
                                    </TableCell>
                                    <TableCell className={classes.btnWrapper}>
                                        {this.getActionButtons(orderStatus,item,item.order_items.data[0].id)}
                                        </TableCell>
                                  </TableRow>
                                );
                              })
                            :
                            <TableRow>
                          <TableCell colSpan={7} >
                            <Typography variant="h6" style={webStyle.text}>
                              {configJSON.noDataAvailableText}
                            </Typography>
                          </TableCell>
                        </TableRow>
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {this.state.ordersInfo?.length > 0 && (
                    <Pagination
                      className={classes.paginate}
                      data-test-id="pagination"
                      page={this.state.page}
                      count={roundedCount}
                      onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                        this.setState({ page: value });
                        this.getSellerOrderDetails(this.state.tabValue, this.state.filterByValue);
                      }}
                      shape="rounded"
                      siblingCount={0}
                      boundaryCount={1}
                    />
                  )}
                    </Box>
                    
                  </OrdersPanel>
                </div>
               );
            })} 
            
            </>
          }
          </Box>
          </>
          }
        </Box>
        
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const webStyle = {
  tabStyle: {
    backgroundColor: "rgb(255,96,8)",
    top: 0,
  },
  rightMargin: { marginRight: 20 },
  rightPadding: { paddingRight: 20 },
  menuPropStyle: {
      marginTop:'12px',
      boxShadow: '0px 5px 18px rgba(0,0,0,0.17)',
      width: '15.5ch',
      overflowY: 'hidden' as const
  },
  tableHeader: {
    verticalAlign:'top',
    borderBottom:
      "1px solid rgba(224,224,224,1)",
  },
  editIcon: {
    padding: '0',
    display: 'flex', 
    alignItems:'center', 
    justifyContent:'center', 
    marginLeft:'8px' 
  },
  editOptions: {
    marginTop:'12px',
    boxShadow: '0px 5px 18px rgba(0,0,0,0.17)',
    width: '15.5ch',
    height: '160px'
  },
  text: { 
    textAlign: 'center' as const, 
    fontFamily: `"Lato", sans-serif`, 
    padding: '10px' 
  },
  sortMenuStyle : {
    padding: "10px",
    borderRadius: "5px",
    marginTop: "56px",
    maxHeight: 200,
    width: "17ch"
  },
  title: {
    cursor:'pointer',
    padding:'16px 8px'
  },
  statusWrap: {
    border:0, 
    padding:'16px 8px'
  },
  statusGrid: {
    flexWrap: 'nowrap' as const
  }
}
 
export const styles = {
  tab: {
    fontFamily: `"Lato", sans-serif` as const,
    padding: "20px 0px 15px 0px",
    fontSize: "18px",
    fontWeight: 400,
  },
  container: {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as const,
    padding: "30px",
    position: "relative" as const,
    background: "white",
  },
  orderTextWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row" as const,
    flexWrap: "wrap" as const,
    justifyContent: "space-between",
    alignItems: "center" as const,
    marginBottom: 10,
  },
  ordersText: {
    fontSize: "27px",
    fontWeight: 700 as const,
    fontFamily: `"Lato", sans-serif` as const,
  },
  searchWrapper: {
    padding: "20px 0px 15px 0px",
    "@media screen and (max-width:460px)": {
      width: "100%",
    },
  },
  searchOrderText: {
    color: "#9a9a9a",
    fontSize: "12px",
    fontWeight: 600 as const,
    fontFamily: `"Lato", sans-serif` as const,
    marginBottom:'4px'
  },
  searchBoxWrapper: {
    position: "relative" as const,
  },
  contentWrapper: {
    overflowY: "auto" as const,
    overflowX: "hidden" as const,
  },
  tabs: {
    marginLeft: "-12px",
    "&.MuiTabs-scroller": {
      overflowX: "auto",
    },
  },
  ordersWrapper: {
    backgroundColor: "rgb(242,243,244)",
    padding: "0 0 15px 0",
  },
  pendingOrdersWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
  },
  ordersInfo: {
    padding: "0px 0px 0px 10px",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `"Lato", sans-serif` as const,
  },
  optionsWrapper: {
    display: "flex",
    flexDirection: "row" as const,
  },
  tabsLabel: {
    textTransform: "capitalize" as const,
    fontSize: "14px",
    fontWeight: 700 as const,
    color: "#727272",
  },
  layout: {
    backgroundColor: "white",
  },
  tableContainer: {
    border: "0.5px solid #ccc",
    borderRadius: "8px",
  },
  tableHead: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 0
  },
  tableCellContent: {
    display: "flex",
    alignItems: "flex-start",
    borderBottom: "0",
    padding: '16px 10px',
  },
  dateWrapper: {
    display: "block",
    alignItems: "flex-start",
    borderBottom: "0",
  },
  detailsTableHead: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  checkBox: {
    marginRight: "20px",
  },
  tableHeaderText: {
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: "12px",
    textAlign: "left" as const,
    fontWeight: 700 as const,
    color: "#333",
    padding: '16px 10px',
    "&.MuiTableCell-stickyHeader": {
      backgroundColor: "rgba(123,123,123,0.07)",
    },
  },
  tableRowHeaderText: {
    color: "rgb(101, 122, 142)",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: "12px",
    textAlign: "left" as const,
    padding:'16px 8px'
  },
  options: {
    padding: "4px 23px",
    color: "#657A8E",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 16,
    fontWeight: 500 as const,
    border: "1px solid rgba(101, 122, 142, 0.320241)",
    borderRadius: 5,
    textTransform: 'capitalize' as const,
    backgroundColor: "rgb(242,243,244)",
    "@media screen and (max-width:420px)": {
      padding: "7px 12px",
      fontSize: "12px",
    },
  },
  viewOption: {
    marginTop:'20px',
    padding: "5px 21px",
    textTransform: 'capitalize' as const,
    color: "#657A8E",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 14,
    fontWeight: 500 as const,
    border: "1px solid rgba(101, 122, 142, 0.320241)",
    backgroundColor: "#fff",
    borderRadius: 5,
    display: "flex"
  },
  refundOption: {
    padding: "5px 20px", 
    color: "#657A8E", 
    fontFamily: `"Lato", sans-serif` as const, 
    fontSize: 14, 
    fontWeight: 500 as const, 
    border: '0px solid rgba(101, 122, 142, 0.320241)',
    backgroundColor: 'rgb(242,243,244)', 
    borderRadius: 5,
    display:'flex', 
    alignItems:'flex-start', 
    borderBottom:'0',
    cursor: 'pointer',
    textTransform: 'capitalize' as const
  },
  pendingOrderStatus: {
    padding: "4px 16px",
    color: "#ec9e31",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 14,
    fontWeight: 500 as const,
    border: "0px solid #ec9e31",
    backgroundColor: "#ffefd3",
    borderRadius: 5,
    borderBottom: "0",
    textAlign: "center" as const,
    width:'100%',
    justifyContent:'center',
    textTransform: 'uppercase' as const
  },
  completedOrderStatus: {
    padding: "4px 12px",
    width:'100%',
    color: "#60c32b",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 14,
    fontWeight: 500 as const,
    border: "0px solid #ec9e31",
    backgroundColor: "#d4f4c3",
    borderRadius: 5,
    display: "flex",
    alignItems: "flex-start",
    borderBottom: "0",
    textAlign:'center' as const,
    justifyContent:'center',
    textTransform: 'uppercase' as const
  },
  cancelledOrderStatus: {
    width:'100%',
    padding: "4px 23px",
    color: "rgb(255, 85, 111)",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 14,
    fontWeight: 500 as const,
    border: "0px solid #ec9e31",
    backgroundColor: "#FCE4E4",
    borderRadius: 5,
    display: "flex",
    alignItems: "flex-start",
    borderBottom: "0",
    justifyContent:'center',
    textTransform: 'uppercase' as const
  },
  customerOption: {
    fontWeight: 700 as const,
    color: "#000",
    display: "flex",
    alignItems: "flex-start",
    borderBottom: "0",
    fontSize: "14px",
  },
  day: {
    fontWeight: 700 as const,
    color: "#000",
    fontSize: "14px",
    marginBottom:'8px'
  },
  prodImage: {
    height: "100%",
    width: "100%",
    objectFit: "fill" as const,
    
  },
  buyer: {
    fontSize: "14px",
    color: "#697A8D",
    fontWeight: 400 as const,
  },
  buyerName: {
    fontSize: "14px",
    color: "#000",
    fontWeight: 400 as const,
    wordBreak: 'break-all' as const
  },
  detailsWrapper: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start",
    marginTop: "10px",
  },
  printLabelBtn: {
    padding:'5px 20px',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: `"Lato", sans-serif` as const,
    borderRadius: "5px",
  },
  btnWrapper : {
    alignItems:'flex-start', 
    flexDirection:'column' as const, 
    borderBottom:'0', 
  },
  statusText: {
    fontSize: 16,
    fontWeight: 400,
    marginLeft: 14,
    fontFamily: `"Lato", sans-serif` as const,
  },
  delivered: {
    '&.Mui-checked': {
      color: '#60c32b',
    }
  },
  shipping: {
    '&.Mui-checked': {
      color: '#ec9e31',
    }
  },
  processing: {
    '&.Mui-checked': {
      color: 'red',
    }
  },
  checkBoxLabel : {
    color: '#60c32b',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: `"Lato", sans-serif` as const,
    textTransform: 'capitalize' as const
  },
  search: {
    "& .MuiInputBase-root": {
      border: "1px solid #c8c8c8"
    },
    "& .MuiInputBase-input::placeholder": {
      fontFamily: `"Avenir-Medium", sans-serif !important` as const,
      color: '#333333 !important',
      fontSize:'14px !important',
      opacity: 'initial'
    }
  },
  tableBackgrnd: {
    backgroundColor: 'rgba(123,123,123,0.07)'
  },
  filterCheckbox: {
    '&.Mui-checked': {
      color: '#ff6008',
    }
  },
  imageBox: {
    padding:'8px',
    borderRadius: "4px",
    width:'43px', 
    height:'43px', 
    backgroundColor:'rgb(231,231,231)'
  },
  activeSortText: {
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 400 as const,
    fontSize: 14,
    color: "#000"
  },
  sortMenuOption: {
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 400 as const,
    fontSize: 14,
    color: "#666666",
    "&:hover": {
      color: "#000",
      fontWeight: 500 as const,
      backgroundColor: "transparent"
    }
  },
  paginate: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "center"
  },
  tableHeaderTextOne: {
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: "12px",
    textAlign: "left" as const,
    fontWeight: 700 as const,
    color: "#333",
    width:'100px',
    "&.MuiTableCell-stickyHeader": {
      backgroundColor: "rgba(123,123,123,0.07)",
    },
  },
  gst: {
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: "13px",
    color: "#697A8D",
    fontWeight: 400 as const,
  },
  prodTitle: {
    cursor:'pointer',
    padding:'16px 8px',
    maxWidth: '120px !important'
  }
};

export {SellerPartnerOrderManagement}
export default withStyles(styles)(SellerPartnerOrderManagement);
// Customizable Area End
