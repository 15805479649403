import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
import { IDocsStatusData, IDocsStatusResponse, IDocumentInputOnChange, ISellerDocumentUpdateResponse } from "./Interfaces";
import { DateTime } from "luxon";
import { InputProps } from "@material-ui/core/Input";
import { createRef } from "react";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  documents: IDocsStatusData[];
  authToken: string;
  seller_id: string;
  errorMessages: string[];
  reuploadedFiles: File[];
  showReUploadFileModal: boolean;
  rejectionReason: string | null;
  disableButtonOnUpload: boolean;
  file_id: string;
  documentType: string;
  vatReason: string;
  accountNo: string;
  iban: string;
  bankAddress: string;
  name: string;
  bankName: string;
  swiftCode: string;
  vatReasonErrorMsg: string;
  accountErr: string;
  nameErr: string;
  ibanErr: string;
  bankNameErr: string;
  bankAdd: string;
  swiftCodeErr: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerMyDocumentsController extends BlockComponent<Props, S, SS> {
  // Customizable Area
  apiGetSellerDocuments: string = "";
  apiUpdateSellerDocument: string = "";
  inputRef: React.RefObject<InputProps & { click: Function }>;
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      documents: [],
      authToken: "",
      seller_id: "",
      errorMessages: [],
      reuploadedFiles: [],
      showReUploadFileModal: false,
      rejectionReason: "",
      disableButtonOnUpload: false,
      file_id: "",
      documentType: "",
      vatReason: "",
      accountNo: "",
      iban: "",
      bankAddress: "",
      name: "",
      bankName: "",
      swiftCode: "",
      vatReasonErrorMsg: "",
      accountErr: "",
      nameErr: "",
      ibanErr: "",
      bankNameErr: "",
      bankAdd: "",
      swiftCodeErr: ""
    };

    this.inputRef = createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiGetSellerDocuments) {
        this.handleSellerDocumentsResponse(responseJson);
      }
      if (apiRequestCallId === this.apiUpdateSellerDocument) {
        this.handleSellerUpdateDocumentResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleSellerUpdateDocumentResponse = (responseJson: unknown) => {
    const successData = responseJson as ISellerDocumentUpdateResponse;
    if ("data" in successData) {
      this.setState({ showReUploadFileModal: false });
      return this.getSellerDocuments();
    }
    this.setState({ errorMessages: ["Something went wrong. Please try again later!"] });
  };

  handleSellerDocumentsResponse = (responseJson: unknown) => {
    const successData = responseJson as IDocsStatusResponse;
    if ("data" in successData) {
      return this.setState({ documents: successData.data });
    }
    this.setState({ errorMessages: ["Something went wrong. Please try again later!"] });
  };

  handleConvertDate = (date: string) => {
    const luxonDateTime = DateTime.fromISO(date).setZone("local");
    return luxonDateTime.toFormat("LLL dd, yyyy | hh:mm a");
  };

  handleInputOnChange = (inputType: string, value: string) => {
    const updateInputValue = {
      vatReason: () => {
        this.handleVatCertificateInput(value);
        this.setState({ vatReason: value });
      },
      accountNo: () => {
        this.handleAccountNumber(value);
        this.setState({ accountNo: value });
      },
      iban: () => {
        this.handleIBan(value);
        this.setState({ iban: value });
      },
      bankAddress: () => {
        this.handleBankAddress(value);
        this.setState({ bankAddress: value });
      },
      name: () => {
        this.handleName(value);
        this.setState({ name: value });
      },
      bankName: () => {
        this.handleBankName(value);
        this.setState({ bankName: value });
      },
      swiftCode: () => {
        this.handleSwiftCode(value);
        this.setState({ swiftCode: value });
      }
    };
    updateInputValue[inputType as keyof IDocumentInputOnChange]();
  };

  handleVatCertificateInput = (value: string) => {
    const isValidVat = /^^[a-zA-Z0-9 ]*$/.test(value);
    if (!isValidVat) {
      this.setState({ vatReasonErrorMsg: "Input should not contains any special charaters.", disableButtonOnUpload: true });
    } else this.setState({ vatReasonErrorMsg: "", disableButtonOnUpload: false });
  };

  handleAccountNumber = (value: string) => {
    const isValidAccountNo = /^\d{11,20}$/.test(value);
    if (!isValidAccountNo) {
      this.setState({ accountErr: "Account number must be between 11 and 20 digits.", disableButtonOnUpload: true });
    } else this.setState({ accountErr: "", disableButtonOnUpload: false });
  };

  handleName = (value: string) => {
    const isValidName = /^[a-zA-Z ]{3,50}$/.test(value);
    if (!isValidName) {
      this.setState({ nameErr: "Input must be between 3 and 50 characters long and can only contain letters and spaces.", disableButtonOnUpload: true });
    } else this.setState({ nameErr: "", disableButtonOnUpload: false });
  };

  handleIBan = (value: string) => {
    const isValidIban = /^[A-Z]{2}\d{22}$/.test(value);
    if (!isValidIban) {
      this.setState({ ibanErr: "Input should be in IBAN format (e.g AB1234567891234567890123)", disableButtonOnUpload: true });
    } else this.setState({ ibanErr: "", disableButtonOnUpload: false });
  };

  handleBankName = (value: string) => {
    const isValidBankName = /^[a-zA-Z ]{3,50}$/.test(value);
    if (!isValidBankName) {
      this.setState({ bankNameErr: "Input must be between 3 and 50 characters long and can only contain letters and spaces.", disableButtonOnUpload: true });
    } else this.setState({ bankNameErr: "", disableButtonOnUpload: false });
  };

  handleBankAddress = (value: string) => {
    const isValidBankAddress = /^[a-zA-Z0-9 ,\-\/]{20,100}$/.test(value);
    if (!isValidBankAddress) {
      this.setState({ bankAdd: "Input must be between 20 and 100 characters long and can only contain letters and spaces.", disableButtonOnUpload: true });
    } else this.setState({ bankAdd: "", disableButtonOnUpload: false });
  };

  handleSwiftCode = (value: string) => {
    const isValidCode = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{8,11}$/.test(value);
    if (!isValidCode) {
      this.setState({ swiftCodeErr: "Should be Alphanumeric between 8 to 11 characters", disableButtonOnUpload: true });
    } else this.setState({ swiftCodeErr: "", disableButtonOnUpload: false });
  };

  handleGetSellerCredentials = async () => {
    const authToken = await getStorageData("authToken");
    const seller_id = await getStorageData("seller_id");
    if (!authToken || !seller_id) {
      return;
    }
    this.setState({ authToken, seller_id });
    this.getSellerDocuments();
  };

  handleReUploadFile = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    const fileInfo = Object.values(this.state.reuploadedFiles);
    const sizeOfFile: any[] = fileInfo?.map((item: any) => item?.size) || [];
    const totalSizeOfFile = sizeOfFile.length && sizeOfFile?.reduce((a, b) => a + b);

    if (!file) return;
    if (file && file.type !== "application/pdf") {
      return this.setState({ errorMessages: ["Failed to upload. The document is corrupted, or the file size exceeds the limit (max 10 MB). Please upload a PDF document."], disableButtonOnUpload: true });
    }
    const maxLimt = 10 * 1024 * 1024;
    const availableLimit = maxLimt - totalSizeOfFile;
    if (file.size > 10 * 1024 * 1024 || file.size > availableLimit) {
      return this.setState({ errorMessages: ["Failed to upload. The document is corrupted, or the file size exceeds the limit (max 10 MB). Please upload a PDF document."], disableButtonOnUpload: true });
    }
    if (fileInfo.length >= 5) {
      this.setState({ errorMessages: ["Failed to upload. Can not upload more than 5 documents."] });
      return;
    }
    this.setState(prevState => ({
      reuploadedFiles: [...prevState.reuploadedFiles, file],
      errorMessages: [""],
      disableButtonOnUpload: false
    }));
  };

  handleRemoveFile = (index: number) => {
    const reuploadedFiles = this.state.reuploadedFiles?.filter((_, i) => i !== index);
    this.setState({ reuploadedFiles });
  };

  handleIbanCertificateValidation = (input: string) => {
    const expectedLength = 24;
    const regex = /^[A-Z]{2}[0-9]+$/;

    if (input?.length !== expectedLength || !regex.test(input)) {
      return true;
    }

    return false;
  };

  handleSubmitReUploadedFile = () => {
    this.setState({ errorMessages: [] });
    if (this.state.documentType !== "Don't have VAT Certificate" && this.state.documentType !== "Don't have IBAN Certificate") {
      if (!this.state.reuploadedFiles.length) {
        return this.setState({ errorMessages: ["Please attach a document."] });
      }
    }
    if (this.state.documentType === "Don't have VAT Certificate") {
      if (!this.state.vatReason) {
        return this.setState({ errorMessages: ["Please provide a reason."] });
      }
    }
    if (this.state.documentType === "Don't have IBAN Certificate") {
      const { state } = this;
      if (!state.accountNo || !state.iban || !state.bankAddress || !state.name || !state.bankName || !state.swiftCode) {
        return this.setState({ errorMessages: ["Please complete the form."] });
      }
    }
    this.setState({ errorMessages: ["Updating please wait!"] });
    this.updateSellerDocument();
  };

  handleShowReUploadFileModal = () => {
    this.setState({ showReUploadFileModal: !this.state.showReUploadFileModal, errorMessages: [], vatReason: "", accountNo: "", bankAddress: "", name: "", bankName: "", swiftCode: "" });
  };

  handleOpenFileDialog = () => {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.click();
    }
  };

  updateSellerDocument = () => {
    const headers = { token: this.state.authToken };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiUpdateSellerDocument = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiGetSellerDocumentsStatusEndpoint + this.state.seller_id + "/seller_documents/" + this.state.file_id);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    const formData = new FormData();
    if (this.state.documentType !== "Don't have VAT Certificate" && this.state.documentType !== "Don't have IBAN Certificate") {
      this.state.reuploadedFiles &&
        this.state.reuploadedFiles?.map(item => {
          formData.append("document_files[]", item);
        });
    }
    if (this.state.documentType === "Don't have VAT Certificate") {
      formData.append("vat_reason", this.state.vatReason);
    }
    if (this.state.documentType === "Don't have IBAN Certificate") {
      formData.append("account_no", this.state.accountNo);
      formData.append("iban", this.state.iban);
      formData.append("bank_address", this.state.bankAddress);
      formData.append("name", this.state.name);
      formData.append("bank_name", this.state.bankName);
      formData.append("swift_code", this.state.swiftCode);
    }
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSellerDocuments = () => {
    const headers = { "Content-Type": configJSON.contentType, token: this.state.authToken };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetSellerDocuments = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiGetSellerDocumentsStatusEndpoint + this.state.seller_id + "/seller_documents");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount(): Promise<void> {
    this.handleGetSellerCredentials();
  }
  // Customizable Area End
}
