import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { SetCookies } from "../../../framework/src/WebUtilities";
import { ShipDetails } from "./SellerPartnerOrderDetailsController.web";
export const configJSON = require("../src/config.js")

export interface IOrderData {
  id: unknown ,
  order_number: string,
  ordered_at: string,
  time_passed_since_order_placed: string,
  final_price: number,
  accepted: boolean,
  order_status: {
    data: {
      id: string,
      attributes: {
        name: string
      }
    }
  },
  seller: {
    data: {
      id: string,
      attributes: {
        full_name: string
      }
    }
  },
  customer: {
    data: {
      id: string,
      attributes: {
        full_name: string
      }
    }
  },
  order_items: {
    data: [
      {
        id: string,
        attributes: {
          item: {
            catalogue: {
              data: {
                id: string,
                attributes: {
                  product_image: string,
                  product_title: string,
                  product_content: {
                    product_attributes: {
                      unique_psku: string
                    }
                  }
                }
              }
            }
          }
        }
      }
    ]
  },
  shipped_order_details: Array<ShipDetails>
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    tab: string,
    container : string,
    orderTextWrapper: string,
    ordersText : string,
    searchWrapper: string,
    searchOrderText: string,
    searchBoxWrapper: string,
    contentWrapper : string,
    tabs : string,
    ordersWrapper: string,
    tableHead: string,
    checkBox: string,
    tabsLabel: string,
    layout: string,
    tableContainer: string,
    pendingOrdersWrapper: string,
    ordersInfo: string,
    optionsWrapper: string,
    options: string,
    tableHeaderText: string,
    tableRowHeaderText: string,
    customerOption: string,
    pendingOrderStatus: string,
    viewOption: string,
    day: string,
    prodImage: string,
    buyer: string,
    buyerName: string,
    detailsWrapper: string,
    detailsTableHead: string,
    completedOrderStatus: string,
    cancelledOrderStatus: string,
    tableCellContent: string,
    dateWrapper: string,
    printLabelBtn: string,
    btnWrapper: string,
    statusText: string,
    delivered: string,
    shipping: string,
    processing: string,
    checkBoxLabel: string,
    search: string,
    refundOption: string,
    tableBackgrnd: string,
    filterCheckbox: string,
    imageBox: string,
    activeSortText: string,
    sortMenuOption: string,
    paginate: string,
    tableHeaderTextOne: string,
    gst: string,
    prodTitle: string
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  authToken: string;
  searchOrderValue: string;
  tabValue: number;
  selectedOrders: number[];
  ordersInfo: IOrderData[],
  orderDetails: IOrderData[],
  sortByValue: string,
  page: number,
  anchorEl: null | HTMLElement,
  menuOpen: boolean,
  isDelivered: boolean,
  isProcessing: boolean,
  isShipped: boolean,
  orderId: string,
  loading: boolean,
  openFilterAnchor: null | HTMLElement,
  selectedFilter: boolean
  filterByValue: string[],
  totalCount: number,
  menuOptions: string[],
  openSortAnchorEl: null | HTMLElement,
  openShipAnchorEl: null | HTMLElement,
  shipDetails: ShipDetails
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerPartnerOrderMgmtController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  getSellerOrdersApiCallId: string = ""
  updateOrderStatusApiCallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      authToken: "",
      searchOrderValue: '',
      tabValue: 0,
      selectedOrders: [],
      ordersInfo: [],
      orderDetails: [],
      sortByValue: '',
      page: 1,
      anchorEl: null,
      menuOpen: false,
      isDelivered: false,
      isProcessing: false,
      isShipped: false,
      orderId: '',
      loading: false,
      openFilterAnchor: null,
      selectedFilter: false,
      filterByValue: [],
      totalCount: 0,
      menuOptions: [],
      openSortAnchorEl: null,
      openShipAnchorEl: null,
      shipDetails: {id: "", tracking_number:"", courier_name: ""}
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  
  getOrdersStatus = (value: string) => {
    if (value === configJSON.orderedText) {
      return configJSON.paymentDoneText
    }
    if(value === configJSON.processingText){
      return configJSON.processingText
    }
    if(value === configJSON.shippedText){
      return configJSON.shippedText
    }
    if(value === configJSON.deliveredText){
      return configJSON.deliveredText
    }
    if(value === configJSON.rejectedText){
      return configJSON.rejectedText
    }
    if(value === configJSON.cancelledText) {
      return configJSON.cancelledText
    }
    if(value === configJSON.refundedText) {
      return configJSON.refundedText
    }
  }

  handleRefund = (orderId: string) => {
    this.updateOrderStatus(orderId,'refunded')
  }

  getOrderStatusClass = (value:string) => {
    if ((value === configJSON.orderedText || value === configJSON.deliveredText)) {
      return this.props.classes.completedOrderStatus
    }
    if((value === configJSON.processingText) || (value === configJSON.rejectedText) || (value === configJSON.cancelledText || (value === configJSON.refundedText))){
      return this.props.classes.cancelledOrderStatus
    }
    if(value === configJSON.shippedText){
      return this.props.classes.pendingOrderStatus
    }
  }

  handlePartnerOrdersTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ tabValue: value , filterByValue: []})
    this.getSellerOrderDetails(value, [])
  }

  handleSearchPartnerOrders = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchOrderValue : event.target.value})
    this.SearchDebounceUpdate(this.state.tabValue, this.state.filterByValue)
  }

  handleGetSellerToken = async () => {
    const authToken = await getStorageData("authToken");
    this.setState({ authToken });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.getSellerOrdersApiCallId) {
        this.handleOrdersResponse(responseJson);
      }
      if (apiRequestCallId === this.updateOrderStatusApiCallId) {
        this.handleUpdateOrderStatus(responseJson)
      }
    }
  }

  handleUpdateOrderStatus = (responseJson:{data: []}) => {
    if(responseJson.data) {
      this.setState({anchorEl: null})
     this.getSellerOrderDetails(this.state.tabValue, this.state.filterByValue,)
    }
  }

  handleOrdersResponse = (responseJson: any) => {
    if("message" in responseJson) {
      this.setState({loading: false, ordersInfo: [],openFilterAnchor: null,
        openSortAnchorEl: null})
    }
    else {
      const orderData = responseJson.filter((item:IOrderData) => typeof item === 'object' && item !== null)
    const count = responseJson.filter((item:IOrderData) => typeof item !== 'object')
    this.setState({
      loading: false,
      ordersInfo : orderData,
      totalCount: count,
      openFilterAnchor: null,
      openSortAnchorEl: null
    })
    }
  }

  async componentDidMount(): Promise<void> {
    this.handleGetSellerToken();
    this.getSellerOrderDetails(0, this.state.filterByValue)
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if(prevState.tabValue !== this.state.tabValue) {
      this.setState({filterByValue: [], page: 1})
    }
  }

  getSellerOrderDetails = async(tabValue: number, filterValue: string[]) => {
    this.setState({loading: true})
    let endPoint;
    const value = filterValue?.map((value: string) => `&filter_by[]=${value}`)
    const filterQuery = value.join("");
    if(tabValue === 0){
      endPoint = filterQuery ? filterQuery : configJSON.tab0FilterValue
    }
    if(tabValue === 1){
      endPoint = filterQuery ? filterQuery : configJSON.tab1FilterValue
    }
  
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await getStorageData("authToken"),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerOrdersApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.partnerAPIEndpoint + `?sort_by=${this.state.sortByValue}${endPoint}&search_query=${this.state.searchOrderValue}&page=${this.state.page}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(message.id, message);
  }

  getDate = (value: string) => {
    const parsedDate = new Date(value);
    const dayValue = parsedDate.getDate().toString().padStart(2, '0');
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
    const year = parsedDate.getFullYear();
    return `${dayValue}/${month}/${year}`;
  }

  getTime = (value: string) => {
    const parsedDate = new Date(value);
    const hour = parsedDate.getHours().toString().padStart(2, '0');
    const minute = parsedDate.getMinutes().toString().padStart(2, '0');
    
    const ampm = hour >= '12' ? 'PM' : 'AM';
    const setHour = (parseInt(hour) % 12) || 12;
    const formattedHour = setHour.toString().length === 1 ? `0${setHour}`: setHour

    return `${formattedHour}:${minute} ${ampm}`;
  }

  handleEdit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, orderStatus: string, orderId:string, shipped_order_details: ShipDetails) => {
      this.setState({ anchorEl: event.currentTarget, orderId: orderId, isProcessing: false, isShipped: false, isDelivered: false });
      if(orderStatus === configJSON.processingText){
        this.setState({isProcessing : true})
      }
      this.setState({
        shipDetails: shipped_order_details || { id:"", tracking_number: "", courier_name: ""}
      })
      if(orderStatus === configJSON.shippedText){
        this.setState({isShipped : true})
      }
      if(orderStatus === configJSON.deliveredText){
        this.setState({isDelivered : true})
      }
  }

  closeShipModal = () => {
    this.setState({
      openShipAnchorEl: null,
      anchorEl: null
    })
  }

  setFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({openFilterAnchor: event.currentTarget})
  }

  handleFilters = (option: string) => {
    const currentFilterByValue = [...this.state.filterByValue];
    const optionIndex = currentFilterByValue.indexOf(option);
    if (optionIndex === -1) {
      const newFilterByValue = currentFilterByValue.concat(option);
      this.setState({ selectedFilter: true, openFilterAnchor: null,filterByValue: newFilterByValue, page: 1 }, () => this.getSellerOrderDetails(this.state.tabValue, this.state.filterByValue));
      
    } else {
      currentFilterByValue.splice(optionIndex, 1);
      this.setState({ selectedFilter: true, openFilterAnchor: null,filterByValue: currentFilterByValue, page: 1 },() => this.getSellerOrderDetails(this.state.tabValue, this.state.filterByValue));
    }
  }

  handleCloseFilters  = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ openFilterAnchor: null });
}

  handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: null });
}

  handleRejectBtn = (orderId: string) => {
    this.updateOrderStatus(orderId,'rejected')
  } 

  handleActionBtn = (orderId:string,orderItemId:string) => {
    this.updateOrderStatusAccept(orderId,'processing',orderItemId)
  }

  handleDeliveredCheckbox = () => {
    this.setState({isDelivered: !this.state.isDelivered, isProcessing: false, isShipped: false})
    this.updateOrderStatus(this.state.orderId,'delivered')
  }

  handleShippedCheckbox = (event: React.ChangeEvent<HTMLElement>) => {
    if(!this.state.isShipped){
      this.setState({ openShipAnchorEl: event.currentTarget})
    }
    this.setState({isShipped: !this.state.isShipped,isDelivered: false, isProcessing: false })
  }

  handleProcessingCheckbox = () => {
    this.setState({isProcessing: !this.state.isProcessing, isShipped: false, isDelivered: false})
    this.updateOrderStatus(this.state.orderId, 'processing')
  }

  SearchDebounce = (callValue: any, delay: number | string | unknown) => {
    let timer: string | number;
    return async function (...args: any) {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await callValue(...args)
      }, delay);
    };
  }

  SearchDebounceUpdate = this.SearchDebounce(this.getSellerOrderDetails,
    1000)

  updateOrderStatus = async (orderId:string, status: string) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await getStorageData("authToken"),
    };
    const httpBody = {
      "orders": {
        "status": status ,
        "accepted": true
      }
    }
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateOrderStatusApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/${orderId}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePatch
    );
    runEngine.sendMessage(message.id, message);
  }

  updateOrderStatusAccept = async (orderId:string, status: string,orderItemId:string) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await getStorageData("authToken"),
    };
    const httpBody = {
      "orders": {
        "status": status ,
        "accepted": true,
        "order_item_id": orderItemId
      }
    }
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateOrderStatusApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/${orderId}`);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    message.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePatch);
    runEngine.sendMessage(message.id, message);}

  setOptions = () => {
    if(this.state.tabValue === 0){
      return configJSON.tabOneFilterOptions
    }
    if(this.state.tabValue === 1) {
      return configJSON.tabTwoFilterOptions
    }
  }

  getTabColor = (tabValue:number, currentTabValue:number) => {
    return tabValue === currentTabValue ? "white" : "rgb(242,243,244)";
  }

  getCheckBoxColor = (isChecked: boolean) => {
    return isChecked ? '#60c32b': '#33333a'
  }

  getOrderCount = () => {
    return this.state.ordersInfo.length > 1 ? 'Orders' : 'Order'
  }

  setSortValue = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({openSortAnchorEl: event?.currentTarget})
  }

  handleCloseSortMenu = () => {
    this.setState({openSortAnchorEl: null})
  }

  handleSorting = (value: string) => {
    this.setState({sortByValue: value, openSortAnchorEl: null})
    this.getSellerOrderDetails(this.state.tabValue, this.state.filterByValue)
  }

  getRoundedCount = () => {
    const count: number = this.state.totalCount / 10;
    const roundedCount = Number.isInteger(count) ? count : Math.ceil(count); 
    return roundedCount
  }

  getSortStyle = (value: string) => {
    return value === this.state.sortByValue ? this.props.classes.activeSortText : this.props.classes.sortMenuOption
  }

  handleOrderDetails = (orderId: string) => {
    SetCookies("sellerOrderId", orderId, 7)
    this.props.navigation.navigate(configJSON.sellerHomeText, {path: configJSON.partnerOrderDetailsPath})
  }
  // Customizable Area End
}
